// BREAKPOINTS
$bp-medium  : 1299px;
$bp-small   : 1023px;
$bp-desktop : 991px;
$bp-tiny    : 767px;
$bp-smallest: 479px;
$bp-micro   : 320px;

// FONT-WEIGHTS
$bold        : 700;
$medium      : 500;
$regular     : 400;
$light       : 300;

// LINEHEIGHTS
$lh-large       : 1.618;
$lh-medium      : 1.375;
$lh-small       : 1.2;
$lh-smallest    : 1;

//COLORS
$link :        #ccc;
$main-color:   #c51d28;
$dark:         #659eb5;
$darkest:      #3e3341;
$gray:         #424b53;
$gray-light:   #626c76;
$border-color: #5c4c54;
$border-color-light: #dfebef;
$light:         #fafafa;
$white:        #ffffff;