#nav,
#nav-left {
  .section-title {
    font-size: 1.4em;
    margin-top: 10px;
  }

  .nav-panel-inner {
    padding: 0;
  }

  .nav-panel--dropdown {
    box-sizing: border-box;
    width: 100%;
    right: 0;
  }

  a:not(.mini-cart-heading) {
    .caret {
      border: none;
      transform: rotate(-90deg);
      position: absolute;
      top: 50%;
      margin: -8px 0 0 0;
      right: 5px;
    }
  }

  .nav-item {
    .nav-panel--dropdown {
      .nav-item.mega {
        .nav-submenu {
          .nav-item--only-subcategories {
            li {
              a {
                padding: 4px 20px 5px 32px !important;
              }

              &:after {
                left: 15px;
              }
            }
          }
        }
      }
    }
  }

  .nav-item--only-subcategories {
    &.simple {
      .nav-submenu {
        li {
          a {
            padding: 4px 20px 5px 32px !important;

            &:after {
              left: 15px;
            }
          }
        }
      }
    }
  }

  .itemslider-wrapper .owl-controls .owl-buttons {
    top: -38px;
  }

  [id^="nav-holder"] {
    .caret {
      display: none;
    }
  }
}

#nav {
  &:not(.nav-mobile) {
    text-align: right;
    font-size: 0;
  }

  &.nav-mobile {
    @include clearfix;
    padding-bottom: 15px;
    background: #fff;
  }
}

.header-regular {
  #nav,
  #nav-left {
    border: solid rgba(101,158,181,.5);
    border-width: 1px 0 0;

    > .nav-item {
      > .nav-panel--dropdown {
        padding: 0;

        > .nav-item {
          a {
            display: block;
            line-height: 20px;
            padding: 10px 15px;
            text-transform: none;
          }

          > a {
            font-size: 20px;
            line-height: 1.2;
            padding: 12px 15px 13px 40px;
          }
        }
      }
    }
  }
}

#header-nav {
  @include bp(max-width, 959px) {
    .inner-container {
      padding: 0;
    }
  }
}

.trigger-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;

  &:before {
    font-family: 'icomoon4';
    content: '\e905';
    line-height: 1;
    float: left;
    width: 24px;
    height: 18px;
    margin-right: 20px;
  }

  &+span {
    display: inline-block;
    vertical-align: middle;
  }
}

.header-regular {
  #nav:not(.nav-mobile) {
    > li {
      float: left;
      box-sizing: border-box;

      //&:not(.extended-width) {
      //  a {
          //color: #424b53;

          //&:hover {
          //  color: $white;
          //}
      //  }
      //}

      &.extended-width.level-top {
        text-transform: uppercase;
        width: 300px;
        position: relative;

        > a {
          padding-left: 40px;

          &:before {
            font-family: 'icomoon4';
            content: '\e905';
            line-height: 1;
            margin-right: 20px;
          }
        }
      }

      > .nav-submenu {
        li {
          a {
            &:hover {
              background: $main-color;
              color: $white;
            }
          }
        }
      }
    }
  }

  .nav-item {
    &.level0:not(.extended-width) {
      > a {
        padding: 0 40px;
        color: $gray;

        @include bp(max-width, 1080px){
          padding: 0 20px;
        }

        &:hover {
          color: $white;
        }
      }

      &.active {
        > a {
          color: $white;
        }
      }

      //& +.level0:not(.extended-width) {
      //  > a {
      //    &:before {
      //      position: absolute;
      //      content: '';
      //      top: 50%;
      //      left: -2px;
      //      width: 4px;
      //      height: 4px;
      //      background: $gray;
      //      border-radius: 50%;
      //      overflow: hidden;
      //    }
      //  }
      //}
    }
  }
}

.dropdown {
  .dropdown-content {
    > li {
      > a,
      > span {
        padding: 5px 10px;
        box-sizing: border-box;
      }

      &:hover {
        background: $main-color;
        color: #fff;

        a {
          color: #fff;
        }
      }
    }
  }
}

#sticky-nav,
#sticky-catalog-menu {
  display: none;
  visibility: hidden;
}

.sticky-header {
  .dropdown .dropdown-heading.cover > span {
    padding: 0 16px;
  }

  #sticky-catalog-menu {
    display: block;
    float: left;
    visibility: visible;
  }

  .nav-item--home {
    display: none !important;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 54px;

    .ic-home {
      font-size: 20px;
    }
  }
}

#sticky-catalog-menu {
  .sticky-main-nav {
    > li {
      display: block;

      &:hover {
        > a {
          color: $white;
        }
      }

      > .nav-submenu {
        left: 100% !important;
        top: 0 !important;
      }
    }
  }
}

.nav-regular {
  .classic {
    > .nav-panel--dropdown {
      padding: 0;
    }
  }

  .nav-panel {
    li.mega {
      &:hover {
        > .nav-panel--dropdown {
          left: 100%;
        }
      }
    }
  }

  .nav-submenu--mega > li.classic:hover > .nav-panel--dropdown {
    left: 100%;
  }
}

.the-slideshow-wrapper {
  @include clearfix;

  .the-slideshow {
    @include bp(max-width, 959px) {
      width: 100%;
    }
  }

  &.fixed-nav {
    @include bp(min-width, 960px) {
      //display: table;
      //width: 100%;
      //table-layout: fixed;

      //> div {
      //display: table-cell;
      //vertical-align: top;
      //float: none;
      //}

      .vert-nav {
        //position: relative;

        //.nav-regular {
        //  position: absolute;
        //  top: 0;
        //  left: 0;
        //  right: 0;
        //  min-height: 100%;
        //}

        //& + .the-slideshow {
        //  float: none !important;
        //}
      }
    }
  }
}

#nav-left {
  > li {
    position: relative;

    > .nav-submenu {
      left: 100% !important;
      top: 0 !important;
    }

    .nav-submenu {
      padding: 12px 0;
      //min-height: 100%;
      box-sizing: border-box;

      .caret {
        border: none;
      }
    }
  }

  .nav-item.first > a:before {
    display: none;
  }
}

.header-container {
  .dropdown {
    .dropdown-heading {
      //padding-bottom: 4px;
      //box-sizing: border-box;

      > span {
        white-space: nowrap;
      }

      //.caret {
      //position: absolute;
      //right: 2px;
      //top: 50%;
      //margin-top: -5px;
      //}

      //&.skip-active {
      //position: relative;
      //}
    }

    //&.open {
    //  .dropdown-heading {
    //	&:after {
    //	  position: absolute;
    //	  content: '';
    //	  width: 100%;
    //	  height: 8px;
    //	  left: 0;
    //	  right: 0;
    //	  bottom: -4px;
    //	  background: #ffffff;
    //	  z-index: 4002;
    //	}
    //  }
    //}
  }
}