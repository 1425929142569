.catalog-category-view {
  .main {
    padding-bottom: 60px;
  }

  .post-list {
    .item {
      border: 1px solid #e6f0f5;
      padding: 30px;
    }

    .blog-post-image-wrapper {
      margin-left: 0;
    }
  }
}

.category-products-grid {
  margin: 0 -1%;

  @include bp(max-width, $bp_tiny) {
    margin: 0;
  }

  .item {
    margin-bottom: 2%;

    .item-inner {
      border: 1px solid $border-color-light;

      &:hover {
        box-shadow: inset 0 0 0 1px $main-color !important;
        border-color: $main-color;
      }
    }
  }
}

.other-categories {
  @include clearfix;
  margin: 0 -10px;

  .category-inner {
    float: left;
    width: (100% / 3);
    padding: 0 10px;
    margin-bottom: 20px;
    box-sizing: border-box;

    @include bp(max-width, $bp-tiny) {
      width: 100%;

      .listing-category-image {
        display: block;
        text-align: center;
        margin-top: 15px;
      }
    }
  }

  .category-image-wrapper {
    border: 1px solid #e0ecf0;

   span {
      display: block;
    }
  }

  .listing-category-content {
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .listing-category-name {
    font-size: 1rem;
    display: block;
    margin-bottom: 10px;
  }
}

.no-gutter.grid-col2-main {
  width: 75%;
}

#category-tabs {
  .tabs {
    display: none;
  }

  .category-blog {
    margin: 0;

    @include bp(max-width, $bp-tiny) {
      margin: 0 -15px;
    }
  }
}

.no-gutter.grid-col2-sidebar {
  width: 25%;
}

.products-list {
  .item-inner {
    @include clearfix;

    .product-image {
      border: 1px solid #e5e0e6;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &:hover {
      .product-image {
        border-color: $main-color;
      }
    }

    .product-name {
      font-weight: bold;
    }
  }

  .add-to-links {
    li {
      margin: 0 0 5px;

      a {
        position: relative;
        padding-left: 25px;
        text-transform: uppercase;

        &:before {
          font-family: BaseIcons;
          position: absolute;
          top: -1px;
          left: 0;
          font-size: 18px;
          line-height: 1;
        }

        &:hover {
          color: $main-color;
        }
      }

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

#narrow-by-list {
  background: #eef5f8;
  //border: 1px solid #ccd9e1;
  padding: 5px 30px 20px;

  @include bp(max-width, $bp-desktop) {
    padding: 5px 15px 15px;
  }

  dt {
    text-align: center;
    span {
      font-size: 18px;
      font-weight: 300;
      text-transform: uppercase;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 1000%;
        top: 50%;
        left: -1000%;
        background: #ccd9e1;
        margin-left: -14px;
      }
    }
  }
  @include bp(max-width, $bp_tiny) {
    padding: 0;
    dt {
      margin: 0;
      margin-left: -15px;
      padding: 30px 15px 20px;
      overflow: hidden;
      width: 100%;

      &:after {
        display: none;
      }

      span {
        font-size: 36px;
        font-weight: 300;
        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: 1000%;
          top: 50%;
          left: 100%;
          background: #ccd9e1;
          margin-left: 14px;
        }
      }
    }

    dd {
      font-size: 30px;
      font-weight: 300;

      li {
        padding: 20px 0;
      }

      a {
        color: $main_color;
        padding-left: 80px;

        &:before {
          width: 54px;
          height: 54px;
          line-height: 54px;
          font-size: 34px;
          border-width: 2px;
        }
      }
      .slider-wrap {
        padding: 10px 45px 35px 0px;
      }
    }
  }
  @include bp(max-width, 460px) {
    dt {
      span {
        font-size: 26px;
      }
    }

    dd {
      font-size: 24px;

      li {
        padding: 16px 0;
      }

      a {
        color: $main_color;
        padding-left: 60px;

        &:before {
          width: 46px;
          height: 46px;
          line-height: 46px;
          font-size: 30px;
        }
      }
    }
  }

  @include bp(max-width, $bp_tiny) {
    .price {
      ol {
        li:not(:first-child) {
          display: none;
        }
      }
    }
  }
}

.layerednavigation-page-container {
  .filter_result {
    @include bp(max-width, $bp_tiny) {
      display: none;
    }

    & + .actions {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 15px;
      padding: 0;
      height: 110px;
      line-height: 110px;
      font-weight: 300;
      font-size: 30px;

      @include bp(max-width, 460px) {
        font-size: 20px;
        height: 90px;
        line-height: 90px;

        a {
          padding-left: 20px !important;

          &:after {
            width: 20px;
            line-height: 90px !important;
          }
        }
      }

      a {
        padding-left: 40px;
        color: $main_color;

        &:after {
          content: '\e20f';
          font-family: BaseIcons;
          display: block;
          position: absolute;
          top: 0;
          left: 0px;
          width: 30px;
          height: 100%;
          line-height: 110px;
        }
      }
    }
  }
}

.sidebar {
  //border: 1px solid $border-color-light;
  //padding: 22px 2%;
  box-sizing: border-box;

  @include bp(max-width, $bp-tiny) {
    border: none;
    margin: 0;
    padding: 0;
  }

  & + .col-main {
    .page-title {
      margin-top: -8px;
      margin-bottom: 18px;
    }
  }

  .filter_result {
    padding: 10px 30px 0;
    border: 1px solid #ccd9e1;
    border-bottom: none;
  }

  .actions {
    padding: 10px 30px 20px;
    border: 1px solid #ccd9e1;
    border-top: none;
  }
  .block-subtitle {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
  }
  .currently {
    font-size: 16px;
    font-weight: 500;

    li > span {
      display: block;

      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .label {
      font-weight: 300;
    }
    .btn-remove {
      color: $main-color;
      margin-right: 5px;
    }
  }
  .actions {
    padding-top: 10px;
    a {
      font-weight: 300 !important;
      font-size: 16px !important;
    }
  }

  .block {
    margin-right: 0;
  }

  div.block-layered-nav div.layerednavigation-slider div.handle {
    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      right: -9px;
      top: -3px;
      border-top: 9px solid transparent;
      border-left: 6px solid #ff9631;
      border-bottom: 9px solid transparent;
    }

    &:last-child {
      &:after {
        right: auto;
        left: -9px;
        border-left: none;
        border-right: 6px solid #ff9631;
      }
    }
  }

  + .col-main {
    padding-left: 2%;
    box-sizing: border-box;

    @include bp(max-width, $bp-tiny) {
      padding-left: 0;
    }

    .layerednavigation-narrow-by-list {
      li {
        display: inline-block;
        vertical-align: top;
        width: 20%;

        @include bp(max-width, 991px) {
          width: 33%;
        }
      }
    }
  }

  .block-layered-nav .block-content > dl > dt {
    overflow: hidden;
    font-size: 1rem;

    span {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 1000%;
        top: 50%;
        left: 100%;
        background: $border-color-light;
        margin-left: 14px;
      }
    }
  }
}

@include bp(max-width, $bp_tiny) {
  .layerednavigation-filters-left {
    background: #f5f5f5;

    &, & .block-subtitle--filter {
      padding: 0 15px;
      width: 100%;
      margin-left: -15px;
    }

    .block-subtitle--filter {
      background: #e9edee;
      height: 110px;
      line-height: 110px;
      font-size: 36px;
      text-transform: uppercase;
      font-weight: 300;

      @include bp(max-width, 460px) {
        height: 90px;
        line-height: 90px;
        font-size: 26px;
      }

      &:after {
        font-family: 'icomoon4';
        content: '\e900';
        line-height: 18px;
        text-align: center;
        font-size: 20px;
        display: inline-block;
        width: 30px;
        height: 18px;
        margin-left: 10px;
        position: relative;
        top: 0;
        left: 0;
        transform: rotate(90deg);
      }

      &.active:after {
        transform: rotate(-90deg);
      }
    }
  }
}


.pager {
  .pages {
    > strong {
      display: none;
    }

    li {
      height: 48px;
      position: relative;

      border: 1px solid transparent;
      @include trs(border-color .3s ease);

      &.current,
      a {
        height: 48px;
        line-height: 48px;
      }

      &.current {
        font-weight: bold;
        color: $main-color;
      }

      &:hover,
      &.current {
        a {
          color: $main-color;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          left: 0;
          bottom: -13px;
          background: $main-color;
        }
      }

      &.next {
        float: right;
      }

      &.previous {
        float: left;
      }

      &.next,
      &.previous {
        border: 1px solid $border-color-light;
        line-height: 48px;
        height: 48px;
        box-size: border-box;
        border-radius: 4px;
        text-transform: lowercase;

        &:after {
          display: none;
        }

        a {
          padding: 0 35px;

          > * {
            display: inline-block;
            line-height: 48px;
            margin: 0;
          }

          @include bp(max-width, 960px) {
            padding: 0 11px;
            > span {
              display: none;
            }
          }
        }

        i {
          font-size: 16px;
          line-height: 1;
          width: 26px;
          text-align: center;

          &:before {
            content: "\e900";
            position: relative;
            top: 2px;
            font-family: icomoon4 !important;
          }
          &.ic-right:before {
            transform: rotate(0deg)
          }
          &.ic-left:before {
            transform: rotate(180deg)
          }
        }
      }
    }
  }
}