.catalog-product-view {
  .main {
    .inner-container {
      overflow: hidden;
    }
  }
}

.product-view {
  //@include bp(max-width, $bp_tiny) {

    //h2.title_with_lines {
    //  text-align: left;
    //  padding: 0;
    //  span {
    //    padding: 0;
    //    font-size: 35px;
    //    &:before, &:after {
    //      display: none;
    //    }
    //  }
    //}
  //}

  //.gen-tabs {
  //  .tabs {
  //    padding: 0 10px;
  //  }
  //  .tabs,
  //  .tabs-panels {
  //    position: relative;
  //
  //    * {
  //      position: relative;
  //      z-index: 2;
  //    }
  //
  //    &:before {
  //      content: '';
  //      position: absolute;
  //      width: 100vw;
  //      height: 100%;
  //      top: 0;
  //      left: 50%;
  //      margin-left: -50vw;
  //      background: inherit;
  //    }
  //    //width: 100vw;
  //    //position: relative;
  //    //left: 50%;
  //    //margin-left: -50vw;
  //  }
  //}

  //.custom-sidebar-right {
  //  color: $main-color;
  //  display: block;
  //  float: none !important;
  //
  //  &:before {
  //    content: '';
  //    display: block;
  //    clear: both;
  //  }
  //
  //  .inner {
  //    padding: 0;
  //  }
  //
  //  .gen-tabs .acctab {
  //    background: #ebebeb;
  //    padding: 0 10px;
  //    margin-left: -10px;
  //    width: 100%;
  //    border-color: #ccd9e1;
  //
  //    &:after {
  //      content: '\e913';
  //      font-family: 'icomoon4';
  //      font-size: 10px;
  //      margin-left: 5px;
  //    }
  //
  //    &.current {
  //      border-color: #005173;
  //    }
  //  }
  //
  //  .tabs {
  //    li {
  //      margin-right: 50px;
  //    }
  //    a {
  //      color: $main-color;
  //      font-size: 21px;
  //      font-weight: 300;
  //      position: relative;
  //      padding: 0;
  //
  //      &:after {
  //        content: '';
  //        display: block;
  //        position: absolute;
  //        bottom: 0;
  //        left: 0;
  //        width: 0;
  //        height: 3px;
  //        background: $main-color;
  //        @include trs(width, 0.3s);
  //      }
  //
  //      &.current {
  //        text-shadow: 0 1px 0, 1px 0 0;
  //      }
  //
  //      &.current:after,
  //      &:hover:after {
  //        width: 100%;
  //      }
  //    }
  //  }
  //
  //  .tabs-panels {
  //    padding: 40px 10px 20px;
  //    box-sizing: border-box;
  //    @include bp(max-width, 1041px) {
  //      padding: 0;
  //
  //      .acctab {
  //        text-align: center;
  //      }
  //    }
  //  }
  //}

  > form {
    margin-bottom: 60px;

    @include bp(max-width, $bp-tiny) {
      margin-bottom: 30px;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .add-to-box {
    margin-top: 0;
  }

  .product-shop {
    width: 50%;
    margin: 0;
    padding-left: 60px;
    box-sizing: border-box;

    @include bp(min-width, $bp-medium) {
      width: calc(100% - 600px);
    }

    @include bp(max-width, 860px){
      display: flex;
      flex-direction: column;
      .container1-wrapper{
        order: -1;
      }
    }

    .product-name {
      margin-bottom: 0;
    }

    .price-box {
      .price_title {
        display: block;
        font-size: 16px;
        font-weight: 300;
      }

      .special-price .price,
      .regular-price .price,
      .price-as-configured .price {
        color: inherit !important;
        font-size: 28px;
        font-weight: 500;
      }
      @include bp(max-width, $bp-tiny) {
        text-align: center;
      }
    }

    &.not-salable {
      .price-box {
        .special-price .price,
        .regular-price .price,
        .price-as-configured .price {
          color: #d6d4d7 !important;
        }
      }
    }

    .add-to-links {
      text-transform: uppercase;

      a {
        &:hover {
          color: $main-color;

          .ic {
            color: $main-color;
          }
        }

        .ic {
          color: #b0a2ac;
          font-size: 22px;
          margin-right: 5px;
        }
      }
    }
  }

  .ratings, .no-rating {
    margin-top: 0;
    text-align: right;
  }

  .features {
    font-weight: 300;

    .medium_w {
      font-weight: 500;
    }
  }

  .sku {
    margin-top: 0;

    .label {
      font-weight: 300;
    }

    .value {
      font-weight: 500;
      color: $main-color;
    }
  }

  .short-description {
    padding: 20px 0 35px;
    margin: 0;
    line-height: 1.5;
    font-weight: 300;
    font-size: 16px;
  }

  .product-type-data {
    .availability-only {
      background: #faf2ec;
      line-height: 36px;
      padding: 0 14px;
      border-radius: 5px;
    }

    .item-options {
      dd {
        float: none;
      }
    }
  }

  .product-shop {
    .btn-cart {
      //@extend %button;
      width: 210px;
      margin-right: 42px;
      height: 50px;
      margin-bottom: 10px;
      border: 2px solid $main-color;
      border-radius: 3px;
      background: $main-color;
      font-size: 18px;
      font-weight: 700;

      &:hover {
        background: #fff;
        color: $main-color;
      }

      @include bp(max-width, $bp_tiny) {
        margin-right: 0;
        height: 90px;
        width: 100%;
        font-size: 38px;
      }
    }
  }

  .share-icons {
    @include bp(max-width, $bp-tiny) {
      text-align: center;
    }
  }

  .product-options {
    p.required {
      top: 0;
    }
  }

  .qty-wrapper {
    .ui-spinner {
      border-color: #ddd;
      @include trs(border-color 300ms ease-in-out);

      &:hover {
        border-color: #aaa;
      }

      .ui-state-default {
        border-color: #ddd;
      }

      .ui-state-hover {
        border-color: #aaa;
      }
    }

    .qty {
      margin: 0 16px 0 0;
      border: 0;
    }
  }

  .sticker-wrapper {
    .sticker {
      padding: 8px 10px;
    }
  }

  #srka-product-review-list .options li .option {
    width: 18px;
    height: 17px;
    background: url(../images/rating.png) 0 0 no-repeat;
  }

  .benefits-container {
    padding: 0;

    .benefit-heading {
      display: block;
      margin-bottom: 10px;
    }

    .img-wrap {
      width: 38px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 22px;
      margin-bottom: 0;

      & + .benefit-title {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
      }
    }

    //@include bp(max-width, $bp-desktop) {
    //  .benefit-title,
    //  .benefit-text {
    //    font-size: 14px;
    //    letter-spacing: -1px;
    //  }
    //}

    .benefit {
      display: inline-block;
      vertical-align: top;
      width: (100% / 3);

      @include bp(max-width, $bp-tiny) {
        width: 100%;
        text-align: center;
      }

      .benefit-title {
        border-bottom: 2px solid #d9e7ed;
        padding-bottom: 5px;
      }

      .benefit_img,
      .benefit_description {
        display: inline-block;
        vertical-align: middle;
      }

      .benefit_description {
        text-align: left;
      }
    }
  }

  @include bp(max-width, $bp-tiny) {
    .add-to-cart {
      text-align: center;

      .btn-cart {
        margin-top: 10px;
      }
    }
  }

  @include bp(max-width, $bp-smallest) {
    .box-reviews {
      .form-add {
        .form-list {
          input.input-text,
          textarea {
            width: 100%;
          }
        }
      }
    }

    .action-box {
      text-align: center;
    }
  }

  .availability-only {
    &.expanded {
      .caret {
        transform: rotate(180deg);
      }
    }
  }

  .availability {
    color: #aab7c0;
    text-transform: uppercase;
    background: #eaeceb;
    padding: 0 40px;
    border-radius: 4px;
    line-height: 50px;
    font-size: 22px;
    font-weight: 500;
    display: inline-block;
  }

  .product-img-column {
    width: 50%;
    box-sizing: border-box;

    @include bp(min-width, $bp-medium) {
      width: 600px;
    }

    img {
      margin: 0 auto;
    }

    .itemslider-thumbnails {
      max-width: 100%;
      margin: 0;

      //@include bp(max-width, 960px) {
      //  max-width: 100% !important;
      //}
      //@include bp(max-width, 860px) {
      //  max-width: 320px !important;
      //  margin: 0 auto !important;
      //}
      //@include bp(max-width, 440px) {
      //  margin: 0 40px !important;
      //}
    }
  }

  .img-box {
    .product-image {
      @include bp(max-width, 860px) {
        width: 100%;
      }
    }

    .more-images {
      margin: 0 40px;

      .owl-wrapper-outer {
        padding: 5px 0 0 !important;
      }

      .item {
        text-align: center;

        a {
          overflow: visible;
          border: 4px solid rgba(0, 0, 0, 0);
          //margin: 0 2px;
          position: relative;
          box-sizing: border-box;
          @include trs(border-color .3s ease);

          &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            left: 50%;
            margin-left: -6px;
            top: -1px;
            @include trs(.3s ease);
          }

          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 0;
            left: 0;
            //bottom: -1px;
            top: -1px;
            @include trs(height .3s ease);
          }

          &:hover,
          &.active {
            border-color: #bed73c;

            &:after {
              height: 3px;
            }
          }
        }
      }
      .owl-buttons{
        .owl-next{
          margin: 0;
          transform: translateY(-50%) rotate(0deg)!important;
        }
        .owl-prev{
          margin: 0;
          transform: translateY(-50%) rotate(0deg)!important;
        }
      }
      @media only screen and (max-width: 784px) {
        .owl-buttons{
          display: block;
        }
      }
    }
  }

  .box-related {
    .section-title {
      &.padding-right {

        @include bp(min-width, 960px) {
          padding-left: 0;
          text-align: left;
          font-size: 1rem;
          text-transform: uppercase;
        }
      }
    }
  }

  @include bp(max-width, 860px) {
    .product-img-column {
      float: none;
      max-width: 100% !important;
    }
    .product-shop,
    .product-img-column {
      width: 100% !important;
      padding: 0;
    }
  }
}

.products-grid:not(#homepage-blog-slider):not(.category-products-grid),
#itemslider-related {
  padding-top: 40px;

  .item {
    box-sizing: border-box;
    margin: 0 10px;
    border: 1px solid #e0ecf0;

    .product-image {
      border: none;
    }

    //@include bp(max-width, $bp_tiny) {
    //  margin: 0;
    //}
  }
  .owl-controls .owl-pagination .owl-page.active:hover,
  .owl-controls .owl-pagination .owl-page.active {
    span {
      background: $main-color;
    }
  }
}

//.product-img-column .owl-prev:before {
//    content: '\e901' !important;
//}
//.product-img-column .owl-next:before {
//    content: '\e902' !important;
//}

.product-img-column {
  .product-image {
    img:not(.visible) {
      display: none !important;
    }
  }
}

.product-img-column .owl-prev,
.product-img-column .owl-next {
  transform: rotate(0deg) !important;
}

#review-form {
  thead,
  tbody,
  td {
    border: none;
  }

  thead {
    display: none;
  }

  .buttons-set {
    .button {
      float: none;
    }
  }
}

#product-review-table {
  margin-bottom: 22px;
  display: block;

  th,
  td {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
  }

  .value {
    width: 17px;
    height: 17px;
    padding: 0;
    vertical-align: middle;
    margin: 0 3px;

    &.active,
    &.current {
      input[type="radio"] {
        & + label {
          &:before {
            content: '\e224';
          }
        }
      }
    }
  }

  input[type="radio"] {
    visibility: hidden;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;

      &:before {
        position: absolute;
        font-family: BaseIcons;
        content: '\e223';
        font-size: 17px;
        line-height: 1;
        color: #efa767;
        left: 0;
        top: 0;
      }
    }

    &:checked {
      & + label {
        &:before {
          content: '\e224';
        }
      }
    }
  }
}

.box-additionals-holder {
  //display: table;
  //table-layout: fixed;

  @include bp(max-width, $bp-tiny) {
    display: block;
  }

  .box-additionals-row {
    display: table-row;
  }

  .additional-heading {
    font-size: 2rem;
    color: $gray;
    text-transform: uppercase;
    display: block;
    padding-bottom: 20px;
    border-bottom: 1px solid #b3cfdb;
    margin-bottom: 60px;

    @include bp(max-width, 767px) {
      font-size: 1.5rem;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
  }


  .additional-data {
    br {
      display: none !important;
    }
  }

  @include clearfix;

  .box-additional {
    margin: 0;

    &.box-sidebar {
      width: 30%;
      padding-left: 70px;
      box-sizing: border-box;
      background: #f9f5f2;

      @include bp(max-width, 1320px) {
        padding-left: 50px;
      }

      @include bp(max-width, 1279px) {
        padding-left: 15px;
      }

      @include bp(max-width, 991px) {
        width: 40%;
      }

      @include bp(max-width, 959px) {
        width: 100%;
        padding-left: 0;
        background: none;

        &:after {
          display: none;
        }
      }

      .section-title {
        padding-top: 27px;
        padding-bottom: 26px;
      }

      .itemslider-wrapper {
        .owl-buttons {
          top: -58px;

          div {
            background: none;
          }
        }
      }

      .product {
        .product-image {
          width: 100px;
          border: 1px solid #e4e0e5;
          @include trs(border-color .3s ease);

          img {
            width: 100%;
            height: auto;
          }

          @include bp(max-width, 959px) {
            float: none;
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
          }
        }

        .product-details {
          @include bp(max-width, 959px) {
            margin-left: 0;
            text-align: center;
          }
        }

        &:hover {
          .product-image {
            border-color: $main-color;
          }
        }

        @include bp(max-width, $bp-tiny) {
          margin-bottom: 40px;
        }
      }

      .price-box {
        .special-price,
        .regular-price,
        .price-as-configured {
          .price {
            font-size: 1.125rem;

            @include bp(max-width, $bp-tiny) {
              font-size: 1.5rem;
            }
          }
        }
      }

      .related-add-to-wishlist {
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    //@include bp(max-width, $bp-tiny) {
    //  width: 100%;
    //  display: block;
    //}

    .tabs {
      width: 100%;
      position: relative;

      a {
        font-size: 1rem;
        line-height: 72px;
        text-transform: uppercase;

        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 3px;
          background: $main-color;
          left: 0;
          bottom: 0;
          @include trs(width .3s ease);
        }

        &.current,
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      //&:before {
      //  position: absolute;
      //  content: '';
      //  top: 0;
      //  bottom: 0;
      //  height: 100%;
      //  width: 100px;
      //  background: #f8f1f0;
      //  left: -100px;
      //}
    }

    &:not(.box-sidebar) {
      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100px;
        background: #fffaf6;
        left: -100px;

        @include bp(max-width, 959px) {
          display: none;
        }
      }
    }

    .box-collateral {
      position: relative;

      .tabs-panels {
        padding: 54px 54px 54px 0;
        border: none;

        @include bp(max-width, $bp-tiny) {
          padding: 0;
          margin: 0 -15px;

          .acctab {
            background: #f8f1f0;
            text-align: center;
            border: none;
          }
        }

        .std {
          line-height: 1.85;
        }
      }
    }
  }
}

.box-sidebar {
  .itemslider-related-wrapper {
    .product-name {
      height: 34px;
      overflow: hidden;
      margin-bottom: 8px;
    }
  }
}

.gen-tabs .acctab {
  &:hover,
  &:focus,
  &.current {
    border-color: $main-color;
  }
}

.itemslider-related-wrapper {
  @include bp(max-width, 959px) {
    //display: none;
  }
}

.itemslider-related-mobile-wrapper {
  display: none;

  @include bp(max-width, 959px) {
    display: block;
  }
}

.product-shop-heading {
  @include clearfix;

  .no-left-gutter {
    display: none;
  }
}

.reviews-list {
  li {
    margin: 0 0 20px;
  }

  label {
    width: 100px;
  }

  .review-title {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .review-text {
    padding: 10px 0;

    & + .nobr {
      font-style: italic;
    }
  }

  .input-box {
    width: calc(100% - 100px);
    float: left;
    clear: none;

    .input-text {
      max-width: 100%;
    }
  }
}

#srka-product-review-list .srka-rating-code .label {
  float: none;
  display: inline-block;
  vertical-align: middle;
  width: 110px;

  &:after {
    content: ' *';
    color: #EE001C;
    font-weight: normal;
    font-family: Verdana, Tahoma, Arial, sans-serif;
    font-size: 12px;
  }

  & + .options {
    display: inline-block;
    vertical-align: middle;
    padding-top: 0;
  }
}

.category-title {
  h1 {
    text-transform: uppercase;
    font-weight: 300;
  }
}

.category-products {
  .sort-by {
    color: $main-color;
    font-weight: 300;
    font-size: 16px;
  }
  .chosen-single {
    color: $main-color;
  }
  a[class*="category-"] {
    position: relative;
    &:before {
      display: block;
      font-family: 'icomoon4';
      content: '\e900';
      transform: rotate(90deg);
    }
    &.ic-arrow-up:before {
      transform: rotate(-90deg);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.box-item {
  @include clearfix;
  margin-bottom: 40px;

  > p ,h3{
    float: left;
    width: calc(100% - 480px);
    padding-left: 40px;
    box-sizing: border-box;
    color: #626c76;

    @include bp(max-width, $bp-desktop) {
      width: 50%;
    }

    @include bp(max-width, $bp-tiny) {
      width: 100%;
      padding: 0;
    }

    &:first-child {
      width: 480px;
      padding: 0;

      @include bp(max-width, $bp-desktop) {
        width: 50%;
      }

      @include bp(max-width, $bp-tiny) {
        width: 100%;
        text-align: center;
      }
    }

    > span {
      font-size: 24px;
      margin-bottom: 18px;
      display: block;
    }
  }
}

.char-images{
  margin:30px 0 -10px -10px;
  width: calc(100% + 10px);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  &__content{
    margin: 0 0 10px 10px;
    width: calc(45% - 10px);
    position: relative;
    padding-top: 43%;
    cursor: pointer;
    img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
    @media screen and (max-width:1023px){
      width: calc(50% - 10px);
    }
    @media screen and (max-width:479px) {
      width: calc(100% - 10px);
      padding-top: 62%;
    }
  }
}

.box-additional {
  .additional-data {
    @include clearfix;
    display: block;

    @include bp(max-width, $bp-tiny) {
      margin: 0;
    }
  }

  &.box-features {
    margin-bottom: 40px;

    .additional-data {
      @include clearfix;
      display: block;
      margin: 0 -18px;
    }
  }
  
  .awards-title{
    border: none;
    width: 100%;
    text-transform: uppercase;
    @media only screen and (max-width: 480px) {
      font-size: 1.3em;
    }
  }

  .awards-list{
    margin: 0 0 -20px -20px;
    width: calc(100% + 20px);
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .award-item{
      margin:0 0 20px 20px;
      width: calc(16.66% - 20px);
      cursor: pointer;
      transition: opacity .3s;
      &:hover{
        opacity: .8;
      }
      &__content{
        padding-top: 100%;
        position: relative;
      }
      img{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      @media only screen and (max-width: 1023px) {
        width: calc(25% - 20px);
      }
      @media only screen and (max-width: 480px) {
        width: calc(33.33% - 20px);
      }
      @media only screen and (max-width: 414px) {
        width: calc(50% - 20px);
      }
    }
  }


  .additional-subheading {
    display: block;
    font-size: 24px;
    margin-bottom: 36px;

    &+.additional-data {
      margin-bottom: 40px;

      &.install-data {
        .data-image {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
  }
}

.modal-image{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 10000;
  justify-content: center;
  display: none;
  background: rgba(0, 0, 0, .3);
  padding: 0;
  cursor: pointer;
  &[style*="display: block;"]{
    display: flex!important;
  }
  &__content{
    background: $white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 800px;
    max-width: calc(100% - 30px);
    max-height: calc(100% - 30px);
    height: 800px;
  }
  .modal-close{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    &::before,&::after{
      content: '';
      display: block;
      width: 20px;
      height: 1px;
      position: absolute;
      left: 50%;
      top: 50%;
      background: $gray;
      transition: background .3s;
    }
    &::before{
      transform: translate(-50%,-50%) rotate(45deg);
    }
    &::after{
      transform: translate(-50%,-50%) rotate(-45deg);
    }
    &:hover{
      &::before,&::after{
        background: $main-color;
      }
    }
  }
  img{
    max-width: 90%;
    max-height: 90%;
  }
}

.box-additional-set {
  @include clearfix;

  .box-additional_left {
    @include bp(min-width, 768px) {
      float: left;
      width: 300px;
    }
  }

  .box-additional_right {
    box-sizing: border-box;

    @include bp(min-width, 768px) {
      float: left;
      width: calc(100% - 300px);
      padding-left: 20px;
    }
  }
}

.box-video {
  .current-video {
    margin-bottom: 50px;

    @include bp(max-width, $bp-tiny) {
      margin-bottom: 20px;
    }
  }

  .iframe-holder {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .current-video-name {
    font-size: 24px;
    line-height: 32px;
    color: #659eb5;
    margin-bottom: 20px;
  }

  .available-videos {
    @include clearfix;
    margin: 0 -60px;

    @include bp(max-width, $bp-medium) {
      margin: 0 -30px;
    }

    @include bp(max-width, $bp-tiny) {
      margin: 0 -10px;
    }
  }

  .available-videos-item {
    float: left;
    width: calc(100% / 3);
    padding: 0 60px;
    box-sizing: border-box;

    @include bp(max-width, $bp-medium) {
      padding: 0 30px;
    }

    @include bp(max-width, $bp-tiny) {
      padding: 0 10px;
    }

    .video-name {
      font-size: 14px;
      line-height: 20px;
      color: #659eb5;
    }

    .video-clicker {
      margin-bottom: 20px;
      cursor: pointer;
      @include trs(opacity .2s ease);

      &:hover {
        opacity: .8;
      }
    }
  }
}

.data-characteristic-table {
  .data-characteristic-row {
    @include clearfix;
    display: block;
    padding: 10px 0;
    border-top: 1px dashed #c7d3df;

    &:last-child {
      border-bottom: 1px dashed #c7d3df;
    }
  }

  .data-characteristic {
    width: 50%;
    float: left;
  }

  .data-value {
    width: 50%;
    float: left;
  }
}

.box-item_grid {
  float: left;
  width: 25%;
  padding: 0 18px;
  box-sizing: border-box;
  color: #626c76;
  margin-bottom: 20px;

  @include bp(max-width, $bp-desktop) {
    width: 50%;
  }

  @include bp(max-width, $bp-smallest) {
    width: 100%;
  }

  p {
    font-size: 14px;

    > span {
      display: block;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}

.downloadable-file {
  padding: 15px 0;
  box-sizing: border-box;

  > * {
    display: inline-block;
    vertical-align: middle;
  }

  .file-pdf-img,
  .file-doc-img {
    display: inline-block;
    vertical-align: middle;
    padding-left: 52px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 38px;
      height: 47px;
    }
  }

  .file-pdf-img {
    &:before {
      background: url(../images/svg/p-d-f.svg) 0 0 no-repeat;
    }
  }

  .file-doc-img {
    &:before {
      background: url(../images/svg/d-o-c.svg) 0 0 no-repeat;
    }
  }
}

.show-our-store {
  .link-map {
    font-size: 1rem;
    background: $main-color;
    border: none;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    padding: 20px 0;
    cursor: pointer;
    @include trs(background-color .2s ease);

    &:hover {
      background: $dark;
    }

    @include bp(max-width, $bp-tiny) {
      padding: 10px 0;
    }
  }
}

//tabs
#tabs {
  margin-bottom: 40px;
}
