.resp-tabs-list {
  @include clearfix;
  background: $dark;
  margin-bottom: 40px;

  li {
    display: inline-block;
    font-weight: 500;
    padding: 20px;
    margin: 0;
    list-style: none;
    cursor: pointer;
    float: left;
    color: #fff;
    @include trs(background-color .2s ease);
    position: relative;

    &:hover,
    &.resp-tab-active {
      //background-color: #fff;
      background-color: $main-color;

      //&:before {
      //  display: none;
      //}
    }

    //&:before {
    //  position: absolute;
    //  content: '';
    //  top: 50%;
    //  left: -2px;
    //  width: 4px;
    //  height: 4px;
    //  background: #fff;
    //  border-radius: 50%;
    //  overflow: hidden;
    //}
  }
}


.resp-tabs-container {
  //padding: 0;
  //background-color: #fff;
  clear: left;
}

.resp-accordion {
  cursor: pointer;
  display: none;
  margin: 0;
  padding: 10px 15px;
  background: $dark;
  margin-bottom: 5px;
  color: #fff;
}

.resp-tab-content {
  display: none;
  padding: 15px;
}

.resp-content-active,
.resp-accordion-active {
  display: block;
}

.resp-tab-active {
  border-bottom: none;
  margin-bottom: 0;
  padding: 10px 15px;

  .resp-arrow {
    border: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #fff;
  }
}

.resp-tab-title {
  &:last-child {
    border-bottom: 12px solid #c1c1c1 !important;
    background: blue;
  }
}

/*-----------Vertical tabs-----------*/
.resp-vtabs {
  .resp-tabs-list {
    float: left;
    width: 30%;

    li {
      display: block;
      padding: 15px 15px !important;
      margin: 0;
      cursor: pointer;
      float: none;
    }
  }

  .resp-tabs-container {
    padding: 0;
    float: left;
    width: 68%;
    min-height: 250px;
    border-radius: 4px;
    clear: none;
  }

  .resp-tab-content {
    border: none;
  }

  .resp-tab-active {
    position: relative;
    z-index: 1;
    padding: 14px 15px 15px 14px;
  }
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 3px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #fff;
}

/*-----------Accordion styles-----------*/

.resp-easy-accordion {
  .resp-accordion {
    display: block;

    &:first-child {
      border-top: 1px solid #c1c1c1 !important;
    }
  }

  .resp-tab-content {
    border: 1px solid #c1c1c1;

    &:last-child {
      border-bottom: 1px solid #c1c1c1 !important;
    }
  }
}

.resp-jfit {
  width: 100%;
  margin: 0;
}

.resp-tab-content-active {
  display: block;
}

/*Here your can change the breakpoint to set the accordion, when screen resolution changed*/
@include bp(max-width, $bp-tiny) {
  .resp-tabs-list {
    display: none;
  }

  .resp-accordion {
    display: block;
  }

  .resp-vtabs {
    .resp-tab-content {
      border: 1px solid #C1C1C1;

      &:last-child {
        border-bottom: 1px solid #c1c1c1 !important;
      }
    }

    .resp-tabs-container {
      border: none;
      float: none;
      width: 100%;
      min-height: initial;
      clear: none;
    }
  }

  .resp-accordion-closed {
    display: none !important;
  }
}
