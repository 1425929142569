.cart {
  @include bp(max-width, $bp-tiny) {
	.btn-remove,
	.btn-remove2 {
	  width: auto;
	  height: auto;
	  line-height: 1;

	  &:before {
		margin-right: 5px;
	  }
	}
  }

  .product-image {
	border: 1px solid #e4e0e5;
	overflow: hidden;
	@include trs(border-color .3s ease);

	&:hover {
	  border-color: $main-color;
	}
  }

  .link-edit {
	white-space: nowrap;
	color: $gray;

	&:hover {
	  color: $main-color;
	}

	>* {
	  display: inline-block;
	  vertical-align: middle;
	}

	i {
	  font-size: 1.125rem;
	  line-height: 1;
	}

	span {
	  margin-left: 5px;
	}
  }

  .btns-set {
	@include clearfix;
	padding: 35px 0;

	.checkout-types {
	  @include clearfix;

	  li {
		margin-bottom: 10px;

		a {
		  font-style: italic;
		  color: $main-color;

		  &:hover {
			color: $dark;
		  }
		}
	  }

	  @include bp(max-width, $bp-smallest) {
		float: none;

		button {
		  width: 100%;
		}
	  }
	}
  }

  .totals {
	.button {
	  min-width: 200px;
	  min-height: 49px;
	}

	.btn-continue {
	  @extend %btn-gray;
	  //min-width: 230px;
	}
  }
}

#shopping-cart-totals-table {
  font-size: 1rem;

  td {
	padding: 12px 0;
  }

  tfoot {
	.price {
	  font-size: 21px;
	}
  }
}

.btn-remove-round {
  font-size: 1.5rem;
  line-height: 1.75;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 1px solid #c4bec5;
  border-radius: 50%;
  color: #c4bec5;
  @include trs(color .3s ease, border-color .3s ease);

  &:hover {
	color: $main-color;
	border-color: $main-color;
  }
}

.cart-table-wrapper {
  padding: 30px;
  background: #fafafa;

  .price {
	font-size: 1.3125rem;
	font-weight: bold;
	color: $main-color;
  }
}

%btn-update {
  border: none;
  background: none;
  font-size: 1rem;
  color: $gray;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
  @include trs(color .3s ease);

  &:before {
	position: absolute;
	font-family: BaseIcons;
	content: '\e210';
	left: 0;
	top: 0;
	@include trs(transform .3s ease)
  }

  &:hover {
	color: $main-color;

	&:before {
	  transform: rotate(180deg);
	}
  }
}

.cart-form-heading {
  @include clearfix;
  margin-bottom: 20px;

  .page-title {
	float: left;
	margin-bottom: 0;
  }

  .btn-update {
	@extend %btn-update;
	float: right;
	margin-top: 16px;
  }
}

//Discount

#discount-coupon-form {
  margin-bottom: 30px;
}

.discount {
  h2 {
	font-size: 1rem;
	margin-bottom: 0;
  }

  .discount-form {
	> label {
	  margin-bottom: 15px;
	  color: #8c838e;
	}
  }

  .input-box {
	white-space: nowrap;
	font-size: 0;
	position: relative;
	padding-bottom: 20px;
	max-width: 440px;

	>*:not(.validation-advice) {
	  display: inline-block;
	  vertical-align: top;
	  max-height: 38px;
	}

	.input-text {
	  width: calc(100% - 145px);
	  border-color: #a399a5;
	}

	.btn-use {
	  min-width: 145px;
	  border: none;
	  background: #a399a5;
	  color: $white;
	  font-size: 14px;
	  padding: 11px;
	  cursor: pointer;
	  @include trs(background-color .3s ease);

	  &:hover {
		background-color: $main-color;
	  }
	}

	.validation-advice {
	  font-size: 12px;
	  position: absolute;
	  left: 0;
	  bottom: 0;
	}
  }
}