.homepage-hotcategories {
  padding-top: 20px;
  padding-bottom: 67px;
  background: #edf5f7;

  .banner {
    margin: 0 1px;
    //height: 300px;
  }

  .inner-container {
    width: auto;
  }

  .owl-wrapper{
    @include bp(min-width, 768px){
      width: 100%!important;
      display: flex!important;
      justify-content: center;
    }
  }

  .owl-pagination {
    position: relative;
    top: 30px;
  }

  .hotcategory {
    text-align: center;

    > a {
      position: relative;
      display: inline-block;
      width: 220px;
      @include trs(opacity .3s ease);

      &:hover {
        opacity: .75;
      }

      .hotcategories-title {
        display: block;
        padding: 25px 0 0;
        color: $dark;
        font-size: 24px;
        line-height: 1.33;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .page-banners:not(.owl-carousel) {
    .hotcategory {
      text-align: left;

      a {
        display: table;
        width: 100%;

        img {
          display: table-cell;
          vertical-align: middle;
          padding-right: 14%;
          box-sizing: border-box;
        }

        span {
          display: table-cell;
          vertical-align: middle;
          width: 50%;
        }
      }
    }
  }

  .owl-controls .owl-buttons {
    top: -76px;
    right: 0;
    background: #fff;
    padding-left: 10px;
  }
}

.itemslider-holder:not(.owl-carousel) .item {
  padding-bottom: 65px;
}

.not-full-width {
  .homepage-unitopblog,
  .homepage-productslider,
  .homepage-banners-container {
    &.container {
      max-width: 100%;
      width: 100%;
    }
  }
}

.homepage-unitopblog,
.category-blog {
  padding-top: 50px;

  @include bp(max-width, $bp-tiny) {
    padding-top: 20px;
  }

  //.section-title {
  //  margin-bottom: 40px;
  //
  //  @include bp(max-width, $bp-tiny) {
  //    margin-bottom: 20px;
  //  }
  //}

  .item {
    padding: 34px;
    background: $white;
    margin-bottom: 22px;

    &:nth-child(even) {
      .product-image {
        float: right;

        @include bp(max-width, $bp-tiny) {
          float: none;
        }
      }
    }
  }

  .item-inner {
    @include clearfix;
    margin: 0 -2%;

    >* {
      float: left;
      width: 50%;
      padding: 0 2%;
      box-sizing: border-box;

      @include bp(max-width, $bp-tiny) {
        float: none;
        width: 100%;
      }
    }
  }

  //.product-image {
  //  float: left;
  //  width: 50%;
  //}

  .post-post_content {
    padding-top: 40px;

    @include bp(max-width, $bp-desktop) {
      padding-top: 20px;
    }
  }

  .blog-name {
    margin-bottom: 20px;
    font-size: 36px;
    color: $gray;
    text-transform: uppercase;

    @include bp(max-width, $bp-tiny) {
      font-size: 26px;
      text-align: center;
    }

    @include bp(max-width, $bp-smallest) {
      font-size: 18px;
    }
  }
  
  p {
    font-size: 18px;
    line-height: 1.33;
    color: $gray-light;

    @include bp(max-width, $bp-tiny) {
      font-size: 16px;
    }
  }

  //.itemslider-wrapper .owl-controls {
  //  .owl-buttons {
  //    top: -87px;
  //    background: #fff;
  //    padding-left: 10px;
  //  }
  //
  //  .owl-pagination {
  //    position: relative;
  //    bottom: -40px;
  //  }
  //}
}

.main-bottom-container {
  .homepage-unitopblog {
    margin: 0;
  }
}

.preface {
  margin-bottom: 40px;
}

.additional-bg-color {
  background: #fff;
  padding: 0 95px 60px;
  width: 100%;
  margin-left: -95px;
  margin-bottom: 0;
  position: relative;

  .homepage-benefits-container {
    .owl-item .benefit {
      display: block;
      text-align: center;

      a {
        padding: 0;
      }

      .img-wrap {
        margin: 0;
      }

      img {
        max-height: 46px;
      }
    }

    .owl-pagination {
      position: relative;
      bottom: -20px;
    }
  }

  @include bp(max-width, 1278px) {
    padding: 0 15px 60px;
    margin-left: -15px;
  }

  @include bp(max-width, $bp-desktop) {
    padding: 0 15px 25px;
  }
}

//.homepage-benefits-container {
//  .partners-slide {
//    .owl-buttons {
//      position: absolute;
//      right: 0;
//      top: -132px;
//    }
//  }
//}

.homepage-benefits {
  .section-title {
    padding: 50px 0 20px;
    margin-bottom: 40px;
  }
}

//.title_with_lines {
//  font-size: 36px;
//  overflow: hidden;
//  text-align: center;
//  margin-bottom: 40px;
//  color: $gray;
//
//  @include bp(max-width, $bp-desktop) {
//    padding: 25px 0;
//    margin-bottom: 0;
//  }
//
//  span {
//    position: relative;
//    padding: 0 35px;
//    display: inline-block;
//
//    &:before,
//    &:after {
//      content: '';
//      width: 1000px;
//      height: 1px;
//      background: #b3cfdb;
//      position: absolute;
//      top: 17px;
//    }
//
//    &:before {
//      left: -1000px;
//    }
//
//    &:after {
//      right: -1000px;
//    }
//
//    @include bp(max-width, $bp-tiny) {
//      font-size: 45px;
//
//      &:before, &:after {
//        top: 28px;
//      }
//    }
//
//    @include bp(max-width, 600px) {
//      font-size: 35px;
//
//      &:before, &:after {
//        top: 20px;
//      }
//    }
//
//    @include bp(max-width, 500px) {
//      font-size: 25px;
//      &:before, &:after {
//        top: 17px;
//      }
//    }
//  }
//}

.itemslider .item {
  padding: 0;

  .blog-name {
    text-align: left;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 24px;
    margin: 28px 0 0;
    padding-right: 30px;
    box-sizing: border-box;
    min-height: 48px;

    a {
      color: $main-color;
    }
  }

  > span {
    text-align: left;
    display: block;
    margin-top: 20px;
    color: $main-color;
    font-size: 16px;
    padding-bottom: 5px;
    line-height: 20px;
  }
}

.homepage-container-seotext {
  padding: 0 0 40px;
  width: 100%;

  h3 {
    padding: 25px 0;
    font-size: 26px;

    @include bp(max-width, $bp_tiny) {
      font-size: 45px;
    }
    @include bp(max-width, 600px) {
      font-size: 35px;
    }
    @include bp(max-width, 500px) {
      font-size: 25px;
    }
  }

  .homepage-pagetext {
    font-size: 16px;

    p {
      margin-bottom: 10px;
    }
  }

  @include bp(max-width, 1278px) {
    padding: 0 15px 60px;
    margin-left: -15px;
  }
}

.read_more {
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
  color: $white;
  width: 222px;
  padding: 12px;
  text-transform: uppercase;
  background-color: #659eb5;
  border: none;
  cursor: pointer;

  &:hover {
    background: $main-color;
    color: $white;
  }
}