.footer-container {
  .block-footer-contacts {
    margin-bottom: 10px;
    font-size: 14px;
    color: #e5e1e1;
    line-height: 2;

    .content {
      overflow: hidden;
    }

    .operator-icon {
      float: left;
      margin: 0 10px 0 0;
    }
  }

  .eba_logo {
    //text-align: center;
    height: 70px;

    a {
      height: 100%;
      padding: 0 !important;
    }

    img {
      max-height: 100%;
    }

    @include bp(max-width, 960px) {
      text-align: left;
    }
  }

  .footer-primary {
    background: transparent;
    .block-content > div:not(.no-margin) {
      padding: 5px 0;
    }
    a {
      line-height: 20px;
      &:hover{
        color: rgba(#fff,.8);
      }
    }
  }

  .footer-copyright {
    font-size: 1em;
  }

  .developed-by {
    .ic-brander {
      margin-left: 5px;
      font-size: 12px;

      &:before {
        font-family: 'icomoon4';
      }
    }

    @include bp(max-width, $bp-tiny) {
      text-align: center;
    }
  }

  .footer-logo {
    display: block;
    margin-bottom: 20px;
  }

  .footer-contacts-holder {
    margin-bottom: 15px;
  }

  .callback-footer {
    font-size: 16px;
    color: #fff !important;
  }

  @include bp(max-width, $bp-tiny) {
    text-align: center;

    .block-title {
      padding: 40px 0 30px;
      margin: 0;

      > span {
        margin-right: 5px;
      }

      &.active {
        background: $darkest;

        & + .block-content {
          background: $darkest;
          padding-bottom: 30px;
        }
      }
    }

    .block-footer-contacts {
      .operator-icon {
        float: none;
      }

      .content {
        display: inline;
      }
    }

    .footer-logo {
      margin: 0 auto 20px;
    }

    .block-footer-contacts {
      font-size: 16px;
    }

    .callback-footer {
      display: inline-block;
      vertical-align: top;
      margin-bottom: 50px;

      @include bp(max-width, $bp-smallest) {
        margin-bottom: 20px;
      }
    }

    .level-top {
      font-size: 16px;
      line-height: 2;
    }
  }

  //.footer-catalog {
  //  box-sizing: border-box;
  //
  //  @include bp(max-width, 1199px) {
  //    padding-left: 70px;
  //  }
  //
  //  @include bp(max-width, 991px) {
  //    padding-left: 40px;
  //  }
  //
  //  @include bp(max-width, $bp-tiny) {
  //    padding-left: 0;
  //  }
  //}

  //grid crutch
  //@include bp(min-width, 1200px) {
  //  .footer-catalog {
  //    padding-left: 156px;
  //  }
  //
  //  .footer-info {
  //    padding-left: 110px;
  //    box-sizing: border-box;
  //  }
  //
  //  .footer-social {
  //    padding-left: 75px;
  //    box-sizing: border-box;
  //  }
  //}

  #subscribe-form {
    padding-top: 20px;

    label[for="newsletter"] {
      display: block;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      margin-right: 0;
    }

    .input-text {
      width: calc(100% - 105px);
      height: 36px;
      background: none;
      border-width: 1px 0 1px 1px;
      border-color: $border-color;
      font-size: 1.29em;
      font-weight: normal;
      color: #b2acae;
    }

    .button {
      text-transform: none;
      font-size: 14px;
      width: 105px;
      padding: 10px 6px;
    }

    @include bp(max-width, $bp-tiny) {
      display: block;
      float: none;

      .input-box {
        float: none;
        white-space: normal;
        margin-right: 0;
      }

      .input-text {
        //max-width: 100%;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        border-width: 1px;
        margin-bottom: 15px;
      }

      .button {
        height: 50px;
        width: 100%;
        font-size: 16px;
      }
    }
  }

  .footer-social {
    @include bp(max-width, $bp-tiny) {
      .block-title {
        padding-bottom: 0;
      }
    }
  }

  .social-links {
    min-width: 275px;
    font-size: 0;
    margin: 0 -5px;

    span {
      border: none !important;
      background: #fff;
      color: #7c7567;
      &:hover{
        color: #c51d28;
        background: #fff;
      }
    }

    a {
      font-size: .85rem;
      display: inline-block;
      vertical-align: top;
      padding: 0 5px;
      margin-bottom: 5px;
    }

    //@include bp(max-width, $bp-tiny) {
    //  margin-bottom: 30px;
    //}
  }
}

.footer-primary-container {
  background: transparent;
  @include bp(max-width, $bp-tiny) {
    padding: 0;

    .container {
      width: 100%;
    }

    .footer-primary-top {
      margin: 0 -15px;
    }
  }
}

.footer-bottom {
  .item-left {
    float: left;
    width: 50%;
  }

  .item-right {
    text-align: right;
    line-height: 24px;
    float: right;
    width: 50%;
  }
  
  .clearer {
    border-top: 3px solid #fff;
    padding-top: 18px;
  }

  @include bp(max-width, $bp-tiny) {
    .item {
      float: none;
      width: 100%;
      //line-height: 50px;
      //font-size: 26px;

      //.ic-brander {
      //  font-size: 20px;
      //}
    }
  }
  &-container{
    background: transparent;
  }
}

//@include bp(max-width, 960px) {
  //.footer-bottom {
  //  padding: 36px 0;
  //}

  //.footer-container .inner-container {
    //text-align: left;
    //padding: 0 40px;

    //.footer-catalog {
    //  padding-left: 0;
    //}

    //.footer-primary-top.clearer > div {
    //  width: 100%;
    //}

    //a, span.phone {
    //  display: block;
    //  font-size: 30px;
    //  line-height: 38px;
    //  padding: 20px 0;
    //}
  //}

  //.block-content .social-links {
  //  text-align: center;
  //
  //  a {
  //    display: inline;
  //    font-size: 16px;
  //    line-height: 32px;
  //    padding: 0;
  //    margin: 0 10px;
  //
  //    span.ic {
  //      width: 95px;
  //      height: 95px;
  //      line-height: 95px;
  //      font-size: 46px;
  //    }
  //  }
  //}

  //.footer-bottom .inner-container {
  //  text-align: center;
  //
  //  a {
  //    display: inline;
  //  }
  //}
//}

@include bp(max-width, 640px) {
  .block-content .social-links {
    a {
      span.ic {
        width: 75px;
        height: 75px;
        line-height: 75px;
        font-size: 40px;
      }
    }
  }
}

@include bp(max-width, 490px) {
  .footer-bottom .item {
    font-size: 15px;
  }

  .footer-container {
    .inner-container {
      a, span.phone {
        font-size: 25px;
        padding: 10px 0;
      }
    }
  }

  .block-content .social-links {
    a {
      span.ic {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
    }
  }
}

@include bp(max-width, 380px) {
  .block-content .social-links {
    a {
      margin: 0;
    }
  }
}
