.my-account {
  label[for="subscription"] {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 10px;
	padding-top: 2px;
  }

  .box-account {
	.box {
	  margin-bottom: 30px;
	}
  }

  @include bp(max-width, $bp-smallest) {
	.form-list {
	  .field,
	  .input-text {
		width: 100%;
	  }
	}

	.chosen-container {
	  min-width: 100%;
	}
  }

  .buttons-set {
	.button {
	  float: none;
	  margin-left: 0;
	}
  }

  .box-info {
	.box-content {
	  div {
		>span {
		  display: inline-block;
		  vertical-align: middle;

		  &:first-child {
			font-weight: bold;
			min-width: 100px;
		  }
		}
	  }
	}
  }
}

.block-poll {
  .actions {
	.button {
	  width: 100%;
	}
  }
}

.my-wishlist {
  .data-table tr.even {
	background: #f8f8f8;
  }

  .add-to-cart-alt {
	text-align: center;
  }

  .qty {
	vertical-align: top;
	margin-bottom: 5px;
  }

  .btn-cart {
	vertical-align: top;
  }

  @include bp(max-width, $bp-tiny) {
	.price-box,
	.product-image,
	.product-name {
	  text-align: center;
	}

	.btn-remove {
	  width: auto;
	  height: auto;
	  font-size: 1rem;

	  &:before {
		vertical-align: bottom;
	  }
	}
  }
}

.wishlist-actions {
  padding: 10px 0;
}

.account-login {
  .new-users {
	margin-right: 0;
  }

  .form-list li {
	margin-bottom: 22px;
  }

  .registered-users {
	margin-left: 0;
  }

  .registered-users {
	.buttons-set {
	  margin-bottom: 34px;
	}

	button {
	  width: 150px;
	  margin-right: 15px;
	  margin-bottom: 10px;
	}

	@include bp(max-width, $bp-smallest) {
	  button,
	  .input-text {
		width: 100%;
	  }
	}
  }

  .button {
	min-height: 50px;
	font-weight: bold;
  }
}

#login-form {
  .btn-remember-pass {
	@extend %btn-gray;
	padding-top: 16px;
  }
}

.account-login h2,
#opc-login h3,
.fieldset .legend,
.multiple-checkout .col2-set h2.legend,
.info-set h2.legend {
  font-size: 2rem;
  padding-bottom: 26px;
  border-bottom: 1px solid #dbd6dc;
  margin-bottom: 15px;

  @include bp(max-width, 1199px) {
	font-size: 1.35rem;
  }
}
.account-login,
.account-create {
  h4 {
	font-size: 14px;
  }

  .social-list {
	margin: 0 -5px;
	@include clearfix;

	li {
	  margin: 0;
	}
  }
}

.account-create {
  .actions {
	padding-top: 28px;
	margin-bottom: 42px;
  }

  .fieldset {
	margin-bottom: 0;
  }
}

.my-account,
.account-edit-form,
.forgotpassword-form {
  .actions {
	padding: 22px 0;
  }
}

#my-orders-table {
  .nobr {
	white-space: nowrap !important;
  }

  @include bp(max-width, $bp-tiny) {
	td {
	  padding: 0;
	}

	.product-name {
	  font-size: 1rem;
	  font-weight: bold;
	}

	.price-excl-tax {
	  display: inline-block;
	  margin-top: 10px;
	}

	td.last {
	  .price {
		font-weight: bold;
		font-size: 1rem;
	  }
	}
  }
}