* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
  font: 14px/1.2 "Helvetica Neue", Helvetica, sans-serif;
}

input.input-text:not(#search):not(#qty),
select,
textarea {
  border-color: #a399a5;
  @include trs(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  &:focus {
    border-color: $main-color;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(255, 150, 49, .6);
  }
}

p {
  margin-bottom: 15px;
}

textarea {
  resize: vertical;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
}

#root-wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper {
  height: 100%;
  border-top: 3px solid #c51f29;
}

.unipagetype {
  background: #edf5f7;
}

//Sticky footer
.page {
  @include bp(min-width, 770px) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main-container {
      flex: 1;
    }
  }
  //display: table;
  //width: 100%;
  //height: 100%;
  //table-layout: fixed;

  //.header-container {
  //  display: table-header-group;
  //  height: 1%;
  //}

  //.main-container {
  //  display: table-row;
  //  height: 100%;
  //}

  .footer-container {
    //display: table-footer-group;
    //height: 1%;

    .social-links {
      span {
        border-color: #206284;
      }
    }
  }
}


.main {
  min-height: 100%;

  //&.not-full-width {
  //  border-left: 1px solid #f6f3ef;
  //  border-right: 1px solid #f6f3ef;
  //}
}

.cmsadvanced-page-view.partnery {
  .partners_icons {
    text-align: center;
    padding: 15px 0;
    @include clearfix;

    img {
      display: inline-block;
      height: 200px;
      margin: 10px;
      cursor: pointer;
      position: relative;

      &.lands {
        height: 100px;
        top: 50px;
      }

      @include bp(max-width, 640px) {
        height: 300px;

        &.lands {
          height: 150px;
          top: 0;
        }
      }
    }
  }

  .images_1,
  .images_2,
  .images_3 {
    padding: 15px 0;
    text-align: center;
    @include clearfix;

    &.images_1 {
      img {
        max-width: 48%;
        max-height: 460px;
      }
    }
    &.images_2 {
      img {
        max-width: 48%;
        max-height: 400px;
      }
    }
    &.images_3 {
      img {
        max-width: 31.333%;
        max-height: 460px;
      }
    }

    img {
      margin: 0 1%;
      display: inline-block;
    }
  }
}

%button {
  min-width: 130px;
  border: 2px solid $main-color;
  color: $main-color;
  background: none;
  font-weight: bold;
  letter-spacing: 1px;
  border-radius: 3px;

  &:hover {
    background: $main-color;
    color: $white;
  }
}

%btn-gray {
  box-sizing: border-box;
  font-weight: normal;
  text-transform: none;
  color: #47344c;
  border-color: #c7c2c9;
  min-width: 198px;

  &:hover {
    color: $white;
    background: #c7c2c9;
  }

  @include bp(max-width, 991px) {
    min-width: 130px;
  }
}

.button {
  @extend %button;
}

.btn-search,
.btn-subscribe,
.btn-use {
  border: none;
  min-width: 0;
  color: inherit;
  border-radius: 0;
  font-weight: normal;
  letter-spacing: 0;

  &:hover {
    color: $dark;
  }
}

.btn-remove,
.btn-remove2,
.map-popup-close,
.remember-me-popup-head .remember-me-popup-close {
  color: $main-color;
  line-height: 1;
}

.toolbar {
  .view-mode {
    .ic {
      font-size: 20px;
      @include trs(color 0.3s ease);

      &.grid {
        &:before {
          content: "\e254";
          font-family: 'BaseIcons' !important;
        }
      }
      &.list {
        &:before {
          content: "\e255";
          font-family: 'BaseIcons' !important;
        }
      }
    }
  }

  .sorter {
    @include bp(max-width, $bp-tiny) {
      text-align: left;
      line-height: 78px;
    }
  }
  .sort-by {
    .category-asc,
    .category-desc {
      border: 1px solid $border-color-light;
      border-radius: 3px;
      width: 36px;
      height: 36px;
      line-height: 36px;
      color: $main-color;
      text-align: center;
    }
  }

  .chosen-container {
    margin-right: 22px;

    &.chosen-with-drop {
      .chosen-single {
        border-radius: 3px 3px 0 0;
      }
    }

    .chosen-results {
      .active-result {
        font-size: 16px;
        color: $main_color;
        font-weight: 300;
        padding-left: 16px;

        &:hover {
          background: #f5f5f5;
        }
      }
    }

    @include bp(max-width, $bp-smallest) {
      margin-right: 10px;
    }
  }

  @include bp(max-width, $bp-tiny) {
    .sort-by {
      width: 100%;

      .chosen-container {
        width: calc(100% - 300px) !important;

        //.chosen-single {
          //height: 74px;
          //line-height: 74px;
          //font-size: 24px;
          //border-width: 2px;

          //div {
          //  width: 74px;
          //
          //  &:after {
          //    font-size: 30px;
          //  }
          //}
        //}
      }

      label {
        font-size: 24px;
      }

      a.ic {
        //float: right;
        width: 74px;
        height: 74px;
        line-height: 74px;
        font-size: 44px;
        border-width: 2px;
      }
    }
  }

  .chosen-container {
    width: 200px !important;
  }

  @include bp(max-width, 580px) {
    .sort-by {
      .chosen-container {
        width: calc(100% - 245px) !important;
        //.chosen-single {
          //height: 50px;
          //line-height: 50px;
          //font-size: 15px;

          //div {
          //  width: 50px;
          //
          //  &:after {
          //    font-size: 20px;
          //  }
          //}
        //}
      }

      label {
        font-size: 18px;
      }

      a.ic {
        //float: right;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 30px;
      }
    }
  }

  @include bp(max-width, 440px) {
    .sort-by {
      .chosen-container {
        width: calc(100% - 68px) !important;
      }
      label {
        display: block;
        line-height: 16px;
      }
    }
  }

  .limiter {
    display: none;
  }

  .view-mode {
    .ic {
      font-size: 20px;
      color: #dbd0db;
      @include trs(color .3s ease);
    }

    > label {
      display: none;
    }
  }
}

// blog pager
.breadcrumbs {
  margin-bottom: 20px;
  padding-top: 14px;
}

.category-blog {
  .toolbar {
    border-top: 0;

    .pager {
      border-top: 0;
    }
  }
}

.breadcrumbs {
  li {
    font-size: 16px;
    a {
      color: #005072;
    }
    .sep {
      vertical-align: top;
      margin: 0 12px 0 10px;
      color: #005072;

      &:before {
        content: '/';
      }
    }
  }
}

#sh_button {
  @include bp(max-width, $bp-tiny) {
    min-height: 0;

    .sh_title_text {
      display: none;
    }
  }
}

.price-box {
  line-height: 1.5;

  .special-price,
  .regular-price,
  .price-as-configured {
    .price {
      font-size: 1.31rem;
      color: $main-color !important;
      font-weight: bold;

      .price-currency {
        font-size: 0.72em;
      }
    }
  }

  .old-price {
    .price {
      color: #b2a6b4;
    }
  }
}

.products-grid {
  .item-inner {
    .product-name {
      a {
        font-size: 24px;
        color: $gray-light;

        &:hover {
          color: $dark;
        }
      }
    }

    .product-image {
      //border: 1px solid #e4e0e5;
      @include trs(border-color .3s ease);

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .product-image {
      .sticker-wrapper {
        top: -1px;

        &.top-left {
          left: -1px;
        }

        &.top-right {
          right: -1px;
        }
      }
    }

    .product-image,
    .product-image-wrapper {
      position: static;
    }
  }

  .preview-description {
    font-size: 15px;
    line-height: 21px;
    color: $gray-light;
    font-style: italic;

    strong {
      font-weight: normal;
      font-style: normal;
    }
  }

  .btn-cart {
    @extend %button;
  }

  .availability.out-of-stock {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 30%;
    left: 0;
    margin-top: -20px;
    text-align: center;

    span {
      line-height: 40px;
      padding: 0 15px;
      font-size: 16px;
      color: $main_color;
      font-weight: 500;
      display: inline-block;
      background: rgba(255, 255, 255, 0.77);
    }
  }
}

.no-touch .products-grid .item-inner:hover .product-image {
  border-color: $main-color;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover {
  border-color: #d3d3d3;
}

.benefits-container,
.homepage-benefits {
  margin-bottom: 50px;

  .benefits-grid {
    font-size: 0;
  }

  .benefit {
    > a {
      display: block;
      height: 100%;
      padding: 35px;
      transition: opacity .2s ease-out;
      box-sizing: border-box;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  $class-name: "benefits-per-row";
  //@for $i from 1 through 10 {
  //.#{$class-name}-#{$i} .benefit {
  //  width: (100% / $i);
  //}
  //}

  .img-wrap {
    display: block;
    margin-bottom: 20px;
  }

  .benefit-title {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;

    b {
      font-weight: normal;
      line-height: 1.5;
    }
  }

  .benefit-text {
    display: block;
  }

  .product-view & {
    padding: 15px 50px;

    .img-wrap {
      margin-bottom: 15px;
    }

    .benefit-title {
      margin-bottom: 8px;
      font-size: 16px;
    }

    .benefit {
      > a {
        padding: 15px;
        font-size: 14px;
        line-height: $lh-small;
      }
    }
  }

  //@include bp(max-width, $bp-medium) {
  //  @for $i from 2 through 10 {
  //    .#{$class-name}-#{$i} .benefit {
  //      width: (100% / ($i - 1));
  //    }
  //  }
  //}

  //@include bp(max-width, $bp-small) {
  //  @for $i from 3 through 10 {
  //    .#{$class-name}-#{$i} .benefit {
  //      width: (100% / ($i - 2));
  //    }
  //  }
  //}

  @include bp(max-width, $bp-tiny) {

    .benefit {
      //width: 100%;
      //text-align: center;

      > a {
        padding: 15px;
      }
    }

    .benefit-title {
      font-size: 16px;
    }

    .benefit-text {
      font-size: 1rem;
      line-height: 1.3;
    }

    //@for $i from 2 through 10 {
    //  .#{$class-name}-#{$i} .benefit {
    //    width: (100% / ($i - 1));
    //  }
    //}
  }

  @include bp(max-width, $bp-smallest) {
    @for $i from 3 through 10 {
      .#{$class-name}-#{$i} .benefit {
        //width: (100% / ($i - 2));
        width: 50%;

        @include bp(max-width, $bp-tiny) {
          width: 100%;
          text-align: center;
        }
      }
    }

    .benefit-title {
      font-size: 14px;
    }
  }

  @include bp(max-width, $bp-micro) {
    @for $i from 4 through 10 {
      .#{$class-name}-#{$i} .benefit {
        width: (100% / ($i - 3));
      }
    }
  }
}

.sticker-wrapper {
  .sticker {
    width: auto;
    height: auto;
    padding: 8px 14px;

    @include bp(max-width, 1365px) {
      padding: 8px;
    }
  }

  &.top-right {
    top: 0;
    right: 0;
    .sticker {
      border-radius: 0 0 0 8px;
    }
  }

  &.top-left {
    top: 0;
    left: 0;
    .sticker {
      border-radius: 0 0 8px;
    }
  }
}

.the-slideshow {
  .item {
    position: relative;

    img {
      width: 100%;

      &.mobile {
        display: none;
      }

      @include bp(max-width, $bp-tiny){
        &.desktop {
          display: none;
        }
        &.mobile {
          display: inline;
        }
      }
    }
  }

  .item-description {
    position: absolute;
    display: table;
    padding: 54px 33px 48px 62px;
    left: 84px;
    top: 54px;
    bottom: 54px;
    width: 30%;
    background: rgba(239, 101, 13, 0.4);
    color: $white;

    @include bp(max-width, $bp-medium) {
      padding: 20px;
      left: 60px;
      top: 40px;
      bottom: 40px;
    }
  }

  .item-description_holder {
    display: table-cell;
    vertical-align: middle;
  }

  .main-slider_title-label {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 5px;

    @include bp(max-width, $bp-medium) {
      font-size: 16px;
    }
  }

  .main-slider_title {
    font-size: 42px;
    line-height: 1.1;
    margin-bottom: 20px;

    @include bp(max-width, $bp-medium) {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  .main-slider_description {
    font-size: 18px;
    margin-bottom: 45px;

    @include bp(max-width, $bp-medium) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  .main-slider_button {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    color: $white;
    padding: 12px;
    background: #d50c2d;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      background: $main-color;
    }
  }

  @include bp(max-width, $bp-tiny) {
    .item-description {
      position: static;
      width: 100%;
      background: $gray-light;
    }
  }
}

.item-inner {
  position: relative;
  background: #fff;
  padding: 10px;
}

.section-title {
  font-size: 2rem;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #b3cfdb;
  text-transform: uppercase;
  margin-bottom: 20px;

  @include bp(max-width, 767px) {
    font-size: 1.5rem;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  //&.padding-right {
  //  padding-right: 100px;
  //  padding-left: 100px;
  //  margin-bottom: 14px;
  //  text-align: center;
  //}
}

//owl carousel

.slider-arrows1 .owl-controls {
  .owl-pagination {
    @include bp(min-width, 785px) {
      display: none;
    }
  }

  .owl-pagination {
    .owl-page {
      span {
        background-color: $dark;
      }

      &:hover span {
        background-color: $main-color;
      }

      &.active {
        &:hover span,
        span {
          background-color: $main-color;
        }
      }
    }
  }
}

.itemslider-num {
  font-size: 20px;
  text-align: center;
  padding: 10px 0;

  span {
    padding: 0 30px;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      font-family: BaseIcons;
      content: '\e260';
      font-size: 1.5em;
      top: 50%;
      left: 0;
      margin-top: -18px;
      transform: rotate(90deg);
    }

    &:after {
      left: auto;
      right: 0;
      transform: rotate(-90deg);
    }
  }

  @include bp(min-width, 784px) {
    display: none;
  }
}

.no-pagination {
  .owl-pagination {
    display: none;
  }
}

.itemslider-wrapper {
  margin: 0 -10px;

  .owl-pagination {
    @include bp(max-width, $bp_tiny) {
      display: block;
    }
  }
}

.owl-controls {
  .owl-buttons {
    @include bp(max-width, 784px) {
      display: none;
    }

    div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      margin: 0 8px;
      color: $gray;
      background: none;
      text-indent: 0;
      @include trs(color .3s ease, background-color .3s ease);

      &:before {
        font: 30px/1 'icomoon4';
        content: '\e900';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        color: #7e868e;
      }

      &:hover {
        background: none !important;

        &:before {
          color: $main-color;
        }
      }

      &.owl-prev {
        left: 0;

        &:before {
          transform: rotate(180deg);
        }
      }

      &.owl-next {
        right: 0;
      }
    }
  }
}

.the-slideshow-wrapper {
  .slider-arrows2 {
    overflow: hidden;

    .owl-controls .owl-buttons div {
      width: 74px;
      height: 46px;
      background: none;
      color: $dark;

      &.owl-prev {
        left: 0;
      }

      &.owl-next {
        right: 0;
      }

      &:before {
        font-size: 38px;
      }

      &:hover {
        background: none;
        color: $main-color;
      }
    }
  }
}

.fl {
  float: left;
}

.fr {
  float: right;
}

#uni-main-loader-checkout,
#sub-loader {
  position: fixed;
  z-index: 999;
}

#sub-loader {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../images/brander/opcheckout/ajax-loader-tr.gif") no-repeat 50% 50%;
  z-index: 10000;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: .4;
  }
}

.chosen-container-single {
  .chosen-single {
    text-align: left;
    padding: 0 36px 0 15px;
    border-color: $border-color-light;
    border-radius: 3px;

    div {
      width: 36px;
      height: 100%;
      top: 0;
      border-left: 1px solid $border-color-light;

      &:after {
        position: absolute;
        font-family: 'icomoon4';
        content: "\e900";
        font-size: 10px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-align: center;
        transform: rotate(90deg)
      }

      b {
        display: none;
      }
    }
  }

  .chosen-results li,
  .chosen-drop {
    border-color: $border-color-light;
  }

  &.chosen-with-drop {
    .chosen-single {
      div {
        &:after {
          transform: rotate(-90deg)
        }
      }
    }
  }
}

.sorter {

  .amount {
    display: none;
  }
}

.limiter {
  .chosen-container {
    min-width: 76px;
  }
}

.contacts_address {
  .contact_h {
    font-size: 21px;
    text-transform: uppercase;
  }

  .regions {
    width: 75%;

    @include bp(max-width, 1000px) {
      width: 100%;
    }

    td {
      padding: 20px 0;

      @include bp(max-width, $bp_tiny) {
        display: block;
      }
    }

    p {
      font-size: 16px;

      span {
        font-weight: 300;
      }
    }
  }

  .cities {
    width: 100%;

    @include bp(max-width, $bp_tiny) {
      td {
        display: block;
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
    }

    strong {
      font-size: 21px;
      font-weight: normal;
    }
  }
}

//Contacts
.contacts-wrap {
  padding: 20px 0;

  &.first {
    border-bottom: 1px solid #ccdce3;
  }
  &.second {
    border-top: 1px solid #ccdce3;
  }
  @include clearfix;

  .buttons-set p.required {
    margin: 0 0 10px;
    float: none;
  }

  .contacts-form-holder {
    float: left;
    display: table;
    width: 25%;
    padding-right: 30px;
    box-sizing: border-box;

    @include bp(max-width, 850px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      float: none;
    }

    .shopname {
      font-size: 21px;
      margin-bottom: 35px;
    }

    .phone {
      font-size: 16px;
      font-weight: 300;
      padding: 8px 0;
    }

    #contactForm {
      display: table-footer-group;
      height: 1%;
    }

    .callback-link {
      a {
        display: inline-block;
        height: 40px;
        line-height: 42px;
        padding: 0 20px;
        background: $main-color;
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 3px;
        margin-top: 20px;
      }
    }
  }

  h2.legend,
  .feature .heading {
    font-family: 'PFSquareSansPro', Arial, sans-serif;
    font-weight: bold;
  }

  .contacts-map-holder {
    @include clearfix;
  }

  .map-inputs-set {
    float: left;
    width: 40%;
    padding-right: 15px;
    box-sizing: border-box;

    @include bp(max-width, 769px) {
      float: none;
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }

    span {
      display: block;
      color: #fff;
      background-color: #d11641;
      padding: 10px;
      text-transform: uppercase;
    }
  }

  #pac-input {
    font-size: 15px;
    color: #222;
    text-transform: uppercase;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: none;
  }

  .map-input-box {
    padding-left: 40px;
    position: relative;
    border: 1px solid #659eb5;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      padding: 19px;
      box-sizing: border-box;
      background: #659eb5 url(../images/svg/location-crosshairs.svg) center center no-repeat;
    }
  }

  #map {
    float: right;
    width: 60%;
    height: 600px;
    max-width: 100%;

    @include bp(max-width, 769px) {
      float: none;
      width: 100%;
      height: 300px;
    }
  }

  .feature,
  .phones {
    margin-bottom: 20px;
  }

  .field {
    margin-bottom: 8px;
  }

  .contacts-address {
    font-size: 1.4em;
    padding-top: 20px;
  }

  .form-list input.input-text,
  .field,
  #contactForm textarea {
    width: 100%;
    max-width: 100%;
    resize: vertical;
  }

  @include bp(max-width, 850px) {
    .buttons-set {
      .button {
        width: 100%;
      }
    }
  }

  .contacts-map-holder {
    //width: 500px;

    @include bp(max-width, 850px) {
      width: 100%;
      margin: 0 auto;
      float: none;

      #map {
        margin: 0 auto;
      }
    }
  }
}

//checkboxes
.input-box {
  .checkbox,
  .radio {
    opacity: 0;
    width: 27px;
    height: 27px;
    position: relative;
    cursor: pointer;
    z-index: 2;

    & + .label {
      position: relative;
      margin-left: 0;
      padding-left: 36px;
      cursor: pointer;
      z-index: 1;

      label {
        cursor: pointer;
      }

      &:after {
        position: absolute;
        content: '';
        width: 25px;
        height: 25px;
        border: 1px solid #dadada;
        left: 0;
        top: 2px;
        border-radius: 3px;
      }

      &:before {
        display: block;
        border: none;
        position: absolute;
        font-family: BaseIcons;
        content: '\e607';
        line-height: 1;
        left: 6px;
        top: 8px;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        @include trs(opacity .3s ease, visibility .3s ease);
      }
    }

    &:hover,
    &:checked {
      & + .label {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.control {
  .checkbox {
    opacity: 0;
    display: none;

    & + label {
      position: relative;
      margin-left: 0;
      padding-left: 32px;
      padding-top: 4px;
      cursor: pointer;

      &:after {
        position: absolute;
        //font-family: iconpack1;
        //content: '\e123';
        font-size: 12px;
        width: 23px;
        height: 23px;
        line-height: 1.9;
        text-align: center;
        z-index: 1;
        color: #fff;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity .3s ease, visibility .3s ease;
        transition: opacity .3s ease, visibility .3s ease;
      }

      &:before {
        position: absolute;
        content: '';
        width: 21px;
        height: 21px;
        border: 1px solid #d7d1d1;
        top: 0;
        left: 0;
      }

    }

    & + label:hover,
    &:checked + label {
      &:after {
        opacity: 1;
        visibility: visible;
        background: $main-color;
      }
    }
  }
}

.webforms-callback {
  .callbacks-welcome-message {
    margin-bottom: 20px;
  }

  label {
    width: 105px;
  }

  input,
  textarea {
    width: calc(100% - 110px) !important;
  }

  .validation-advice {
    padding-left: 110px;

    @include bp(max-width, $bp-smallest) {
      padding-left: 0;
    }
  }

  .field {
    textarea {
      width: calc(100% - 95px);
      height: 38px;
      resize: none;
      border-color: #a399a5;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

      &:focus {
        border-color: #005173;
      }
    }
  }
}

.fancybox-inner {
  height: auto !important;
}

@include bp(max-width, $bp-smallest) {
  .webforms-callback input.input-text {
    width: 100%;
  }

  //.section-title.padding-right {
  //  padding-right: 0;
  //  padding-left: 0;
  //}

  .itemgrid.itemgrid-adaptive .item {
    width: 98%;
  }
}

.fancybox-lock {
  #sh_button {
    right: 17px;
  }
}

.compare-table {
  .product-name {
    font-weight: bold;
  }

  td {
    vertical-align: top;
  }

  .link-wishlist {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    .ic-heart {
      margin-right: 5px;
    }
  }
}

.social-list {
  font-size: 0;
  margin: 0 -5px;

  a {
    font-size: .85rem;
    display: inline-block;
    vertical-align: top;
    padding: 0 5px;
    margin-bottom: 5px;
  }
}

.data-table {
  .even {
    background: #f8f1f0;
  }
}

.cart-table-wrapper {
  .data-table {
    .even {
      background: #fafafa;
    }
  }
}

.scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

@media only screen and (max-width: 959px) and (min-width: 768px) {
  .data-table th, .data-table td {
    padding: 5px;
    font-size: 12px;
  }
}

.rating-box {
  width: 120px;
  height: 16px;

  @include mq(retina) {
    width: 100px;
    background-size: 20px 50px;
  }

  .rating {
    height: 16px;
    @include mq(retina) {
      background-size: 20px 50px;
    }
  }
}

.block-layered-nav {
  .brander_layerednavigation-attr,
  [class^='brander_layerednavigation-attr'] {
    &:before {
      position: absolute;
      font-family: 'icomoon4';
      content: '\e901';
      font-size: 14px;
      width: 20px;
      height: 20px;
      line-height: 1;
      border: 1px solid #ccd9e1;
      color: transparent;
      border-radius: 3px;
      box-sizing: border-box;
      text-align: center;
      z-index: 1;
      top: 3px;
      left: 0;
      @include trs(border-color 0.3s ease);
    }
  }
  .brander_layerednavigation-attr,.brander_layerednavigation-attr-selected{
    display: inline-block;
  }
  .brander_layerednavigation-attr-selected,
  .brander_layerednavigation-attr.checked,
  .brander_layerednavigation-attr:hover {
    color: $dark;

    &:before {
      color: $dark;
      border-color: $dark;
    }
  }

  div.layerednavigation-slider {
    div.handle {
      width: 23px !important;
      height: 23px !important;
      background: $main-color;
      border-radius: 50%;
      margin-top: -12px !important;
      border: none !important;

      @include bp(max-width, $bp_tiny) {
        width: 58px !important;
        height: 58px !important;
        margin-top: -29px !important;
      }

      &:after {
        font-family: 'icomoon4';
        display: block;
        content: '\e905' !important;
        border: none !important;
        left: 0 !important;
        top: 0 !important;
        color: #fff;
        font-size: 12px;
        width: 100% !important;
        height: 100% !important;
        text-align: center;
        line-height: 24px;

        @include bp(max-width, $bp_tiny) {
          font-size: 44px;
          line-height: 58px;
          text-indent: -7px;
        }
      }
    }
    .layerednavigation-slider-bar + div.handle:after {
      content: '\e904' !important;
      text-indent: 7px;
    }
  }

  .inpts-wrap {
    [class^="price-range-"] {
      input {
        border-radius: 3px;
        background: none;
        border-color: $border-color-light !important;
        color: $main_color;
      }
    }
  }
  .price-range-btn-wrap {
    margin-top: 20px;
    button {
      border-color: $main-color;
      color: #fff;
      background: $main-color;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        background: none;
        color: $main-color;
      }
    }
  }
}

.portal_block {
  width: 100%;
  box-sizing: border-box;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .items_port {
    border: 1px solid #ccd9e1;
    box-sizing: border-box;
    float: left;
    width: 31.333%;
    margin: 1%;
    text-align: center;

    .item_img {
      max-width: 100%;
      max-height: 100%;
    }

    .title {
      padding: 10px 0 15px;
      display: block;
      font-size: 28px;
      font-weight: 300;
    }

    @include bp(max-width, $bp_tiny) {
      width: 48%;
    }
    @include bp(max-width, 640px) {
      width: 100%;
      margin: 1% 0;
    }
  }
}

.main_portal_block {
  @include clearfix;

  .main_portal_item {
    width: 25%;
    float: left;
    text-align: center;
    box-sizing: border-box;
    padding: 15px;

    @include bp(max-width, 960px) {
      width: 50%;
    }
    @include bp(max-width, 460px) {
      width: 100%;
    }

    a {
      display: block;

      .hotcategories-title {
        display: block;
        padding: 25px 0 0;
        color: $dark;
        font-size: 24px;
        line-height: 1.33;
      }
    }
  }
}

.region_block {
  padding-top: 40px;

  h3 {
    text-transform: uppercase;
    font-size: 21px;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .region {
    width: calc(100% / 3);
    min-height: 150px;
    float: left;
    box-sizing: border-box;
    padding: 15px;
    padding-left: 0;

    @include bp(max-width, 960px) {
      width: 50%;
    }
    @include bp(max-width, 640px) {
      width: 100%;
    }

    .title {
      font-size: 16px;
      padding-bottom: 15px;
    }
    .phone {
      font-size: 16px;
      font-weight: 300;
    }
  }

}

.cities_block {
  padding-bottom: 10px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .citi {
    width: calc(100% / 3);
    min-height: 150px;
    float: left;
    box-sizing: border-box;
    padding: 15px;
    padding-left: 0;

    @include bp(max-width, 960px) {
      width: 50%;
    }
    @include bp(max-width, 640px) {
      width: 100%;
    }

    .title {
      font-size: 21px;
      padding-bottom: 15px;
      text-transform: uppercase;
    }
    .phone {
      font-size: 16px;
      font-weight: 300;
    }
  }
}

/* Scroll to top button
-------------------------------------------------------------- */
#scroll-to-top {
  @include trs(color .3s ease);
  display: none;
  position: fixed;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 32px;
  bottom: 56px;
  right: 25px;
  z-index: 9999;
  border-radius: 3px;
  background: $main-color;
  color: #fff;
  transform: rotate(-90deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  &:before {
    font-family: 'icomoon4';
    content: "\e900";
  }
}

.std {
  vertical-align: middle;
}

.grid__text-editor {
  @include bp(max-width, $bp-tiny) {
    text-align: center;

    table,tr,th,td,tbody,span {
      display: block !important;
      padding: 0 !important;
      width: 100%;
      text-align: center;
    }

    td {
      margin-bottom: 15px;
    }

    p {
      padding: 10px;
    }
  }
}

.std-content {
  color: #626c76;

  .std-title {
    font-size: 20px;
  }

  p {
    font-size: 15px;
    line-height: 21px;
    margin: 1rem 0;
  }

  .std-grid__row {
    @include clearfix;
    margin: 0 -10px 30px;
    padding: 20px 0;
    background: #f3f3f3;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      background: #f3f3f3;
      width: 9999px;
    }

    &:before {
      left: -9999px;
    }

    &:after {
      right: -9999px;
    }
  }

  .std-grid-carousel {
    max-width: 800px;
    margin: 0 auto;
  }

  .std-grid__item {
    //width: 50%;
    //float: left;
    padding: 0 10px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 30px;

    p {
      font-size: 15px;
      line-height: 21px;
    }
  }

  .std-grid__item_inner {
    background: #fff;
    max-width: 390px;
    margin: 0 auto;
  }

  .btn-more {
    background-color: #659eb5;
    display: block;
    text-align: center;
    text-decoration: none;
    height: auto;
    box-sizing: border-box;
    text-transform: uppercase;
    line-height: 25px;
    padding: 9px 20px 9px 20px;
    font-size: 15px;
    color: #fff;
    min-height: 2rem;

    &:hover {
      background: #325968;
    }
  }

  .std-grid__item_img {
    display: block;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }

  .std-grid__item__title {
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    padding: 0 20px;
  }

  .owl-controls {
    .owl-page {
      width: 14px;
      height: 14px;
      margin: 4px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      overflow: hidden;
      background: #626c76;
      @include trs(background-color .2s ease);

      &:hover,
      &.active {
        background: $main-color;
      }
    }

    .owl-buttons div {
      &.owl-next {
        right: -50px;
      }

      &.owl-prev {
        left: -50px;
      }
    }
  }

  .std-item {
    @include clearfix;
    margin: 60px -10px;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      bottom: -30px;
      left: 0;
      right: 0;
      height: 1px;
      background: #b3cfdb;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }

    .std-grid__item__title {
      padding: 0;
    }
  }

  .std-item_img {
    width: 50%;
    box-sizing: border-box;
    padding: 0 10px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include bp(max-width, 767px) {
      width: 100%;
      height: auto !important;
      float: none !important;
      padding: 0;
    }
  }

  .std-item_text {
    padding: 2rem 30px;
    box-sizing: border-box;
    background: #f3f3f3;
    float: left;
    width: 50%;

    @include bp(max-width, 767px) {
      float: none;
      width: 100%;
    }
    
    .grid__text-editor {
      text-align: left;
    }

    p {
      padding: 0;
    }
  }
}



.locations-page{
  .online-shops{
    margin-top: 30px;
    color: #6b7278;
    &__title{
      margin-bottom: 20px;
      font-size: 20px;
      margin-bottom: 20px;
      width: 100%;
      display: block;
    }
    &__page{
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 0 -10px -10px;
      width: calc(100% + 10px);
    }
    .item{
      margin: 0 0 25px 10px;
      width: calc(16.66% - 10px);
      span{
        display: block;
        font-weight: 400;
        font-size: 18px;
      }
      a{
        color: inherit;
        text-decoration: underline;
        transition: color .3s;
        &:hover{
          color: $main-color;
        }
      }
      @media screen and (max-width: 1365px) {
        width: calc(20% - 10px);
      }
      @media screen and (max-width: 1023px) {
        width: calc(25% - 10px);
      }
      @media screen and (max-width: 767px) {
        width: calc(33.33% - 10px);
      }
      @media screen and (max-width: 480px) {
        width: calc(50% - 10px);
      }
      @media screen and (max-width: 314px) {
        width: calc(100% - 10px);
      }
    }
  }
  .map-inputs-set{
    float: none;
    padding-right:0;
    margin-bottom: 20px;
  }
  #map{
    float: none;
    height: auto;
    width: 100%;
    padding-top: 42.6%;
    @media screen and (max-width:1023px) {
      height: auto!important;
      padding-top: 81%;
    }
  }
  .list{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-left: -20px;
    color: #6b7278;
    width: calc(100% + 20px);
    .city{
      margin-left: 20px;
      width: calc(100% - 20px);
      margin-bottom: 10px;
      font-size: 20px;
      color: $gray;
    }
    .item{
      width: calc(25% - 20px);
      margin: 0 0 20px 20px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      .link{
        order: 1;
        margin-top: 5px;
      }
      &+.city{
        margin-top: 20px;
      }
      .name{
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .description{
        margin-bottom: 5px;
      }
      a{
        text-decoration: underline;
        color: inherit;
        transition: color .3s;
        @media screen and (min-width:1025px) {
          &:hover{
            color: $main-color;
          }
        }
        &+ a{
          margin-top: 5px;
        }
      }
      .phone{
        display: flex;
        flex-direction: column;
        a{
          text-decoration: none;
        }
        &+*{
          margin-top: 10px;
          display: block;
        }
        &+a{
          display: inline-block;
        }
      }
      @media screen and (max-width:1365px) {
        width: calc(33.33% - 20px);
      }
      @media screen and (max-width:959px) {
        width: calc(50% - 20px);
      }
      @media screen and (max-width:479px) {
        width: calc(100% - 20px);
      }
    }
  }
}