.header-container {
  //.logo {
  //  max-width: 131px;
  //}

  .nav-regular {
    > .nav-item.first {
      position: relative;

      @include bp(min-width, 800px) {
        box-sizing: border-box;

        > a {
          padding-left: 65px;
          position: relative;

          > .caret {
            display: none;
          }

          @include bp(max-width, 1024px) {
            &:after {
              left: 15px;
            }
          }
        }

        > ul {
          width: 100%;
        }
      }
    }
  }

  #sticky-catalog-menu {
    > a.level-top {
      padding-left: 40px;

      &:before {
        left: 10px;
      }
    }
  }

  .lang-switcher {
    &.dropdown {
      .dropdown-content {
        min-width: 100%;
        box-sizing: border-box;
        padding: 0;
      }
    }

    .flag {
      background-repeat: no-repeat;
      background-position: 0 10px;
    }
  }

  .dropdown.header-contacts {
    .dropdown-heading {
      font-size: 1rem;

      >span {
        padding-left: 3px;
      }
    }

    .dropdown-content {
      min-width: 100%;
      box-sizing: border-box;

      li {
        margin-bottom: 8px;
        white-space: normal;
        clear: both;
        padding-left: 2px;
        font-size: 1rem;
      }

      .operator-icon {
        display: none;
        float: left;
        margin: 0 8px 8px 0;
      }

      .content {
        display: block;
        overflow: hidden;
        margin-bottom: 8px;
      }
    }
  }

  .callback-header {
    margin-right: 0;
  }
}

.callback-header {
  .callback-title {
    display: block;
  }
}

.header-regular {
  #mini-cart-label {
    display: none;
  }
}

#lang-switcher-wrapper-regular {
  text-transform: uppercase;
}

@include bp(max-width, 420px) {
  .header-top-container .container_header_top_block {
    float: none !important;

    .callback-header {
      .nav-item {
        a {
          padding: 0;
          padding-right: 10px;
        }
      }
    }
  }
}


.links-wrapper-separators .links > li {
  position: relative;

  &:not(:first-child):after {
    content: '';
    width: 4px;
    height: 4px;
    border: 1px solid #a8b9c4;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: -3px;
  }

  > a {
    background: none;
    padding: 0px 25px;
  }
}

.user-menu {
  [id$="-wrapper-regular"] {
    float: right;

    @include bp(max-width, 769px){
      float: none;
    }
  }
  
  .dropdown {
    .dropdown-heading {
      font-size: 1.125rem;
      max-height: none;

      #mini-cart-heading {
        position: relative;

        .count {
          position: absolute;
          top: 0;
          right: 5px;
          width: 24px;
          height: 24px;
          background: $main-color;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          line-height: 25px;
          font-size: 14px;
          font-weight: bold;
        }
      }

      .caret {
        display: none;
      }

      .ic {
        font-size: 1.7rem;

        &.ic-cart:before {
          content: '\e911';
          display: block;
          float: right;
          width: 70px;
          height: 70px;
          text-align: center;
          text-indent: -5px;
          line-height: 70px;
          color: #005072;
          font-size: 40px;
          box-sizing: border-box;
          border: 1px solid #ccd9e1;
          border-radius: 50%;
        }
      }
    }
  }
}

.mini-cart-heading {
  .ic-cart {
    font-size: 25px;

    &:before {
      content: '\e911';
    }
  }
}

.hp-blocks-holder {
  .center-column {
    .item-right {
      float: right;
    }
  }

  .lang-switcher {
    padding-top: 20px;

    @include bp(max-width, $bp_tiny) {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
    }
  }

  .skip-link {
    color: #626c76;
    border: solid $dark;
    border-width: 1px 0;

    &.skip-active {
      border-bottom-color: rgba(0,0,0,0);

      &+.skip-search {
        &:before {
          display: none;
        }
      }
    }

    //line-height: 100px;

    //.icon {
      //font-size: 40px;
      //color: $main-color;
      //line-height: 100px;
    //}

    .label {
      text-transform: uppercase;
    }

    &.skip-nav {
      width: calc(100% - 60px);
      text-align: left;
      padding-left: 20px;
      box-sizing: border-box;

      .label {
        display: inline-block !important;
      }
    }

    &.skip-search {
      float: right;
      width: 60px;

      &:before {
        position: absolute;
        content: '';
        left: -1px;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 26px;
        background: rgba(98,108,118,.5);
      }

      .icon {
        font-size: 21px;
      }

      .label {
        display: none;
      }

      &.skip-active {
        background: #a9c9d6;

        &:before {
          display: none;
        }
      }
    }
  }

  .search-wrapper {
    background: #fff;

    .header-mobile & {
      box-shadow: 0 2px 4px 0 rgba(156, 156, 156, 0.5);
    }
  }
}


.mini-account-heading {
  .ic-user {
    &:before {
      font-size: 30px;
    }
  }
}
.dropdown {
  &.is-not-empty {
    .dropdown-heading {
      span {
        color: $main-color;
      }
    }
  }
}

.mini-cart {
  .mini-cart-content {
    .actions {
      button {
        padding: 10px 5px;

        &:last-child {
          float: right;
          margin-right: 0;
        }
      }
    }

    .btn-edit {
      font-size: 0;

      &:before {
        font-size: 14px;
      }
    }

    .btn-remove {
      font-size: 1.3rem;
    }
  }
}

.header-mobile {
  .header {
    width: 100%;

    > .inner-container {
      padding: 0;
    }
  }

  .skip-link {
    height: auto;

    .ic-user {
      font-size: 65px;
    }
    .ic-cart {
     font-size: 50px; 
    }
  }

  .header-top {
    .hp-block {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    .callback-header {
      position: static;
      float: right;
      margin-top: 0;
    }
  }

  .logo-wrapper--mobile {
    .logo {
      max-width: 131px;
      margin: 0 auto 35px;
    }
  }

  #header-nav {
    .nav {
      width: 100%;
    }
  }

  .user-menu {
    border: none;
  }

  .container_header_primary_left_1 {
    display: block;
  }

  @include bp(max-width, 769px) {
    .hp-block {
      .item-right {
        float: none;
        margin-left: 0;
        text-align: left;
      }
    }
  }
}

// SEARCH AUTOCOMPLETE
.search-wrapper {
  .reset-button {
    display: none;
  }

  #search_mini_form {
    @include clearfix;

    .btn-search {
      @include bp(max-width, 769px) {
        color: #fff !important;
      }

      &:before {
        font-family: 'icomoon4';
        content: '\e908';
      }
    }

    .input-text {
      font-size: 18px;
      font-style: italic;
      border-width: 0 0 1px;
      border-radius: 0;
      background: none;
      padding: 0 35px 0 0;
      @include trs(color .2s ease, border-color .2s ease);

      @include bp(min-width, 1200px) {
        width: 300px;
      }

      @include bp(max-width, 769px) {
        width: 100%;
      }

      & + button {
        font-size: 20px;
      }
    }
  }

  .searchautocomplete-search {
    border: 1px solid #d5d5d5;
    box-shadow: 0 3px 10px rgba(0,0,0, 0.15);
    padding: 10px;
    right: 0;
    text-align: center;

    .s_item {
      @include clearfix;
      @include trs( border-color .3s ease);
      margin-bottom: 20px;
      border-bottom: 1px solid #333;

      &:hover {
        border-color: $main-color;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }

    .s_icon {
      margin-bottom: 10px;
    }

    .s_details {
      padding-bottom: 10px;
      box-sizing: border-box;
    }

    .s_description {
      display: none;
    }

    a {
      display: inline-block;
      color: $dark;

      .s_item_name {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 10px;
        @include trs(color .3s ease);
      }

      &:hover {
        .s_item_name {
          color: $dark;
        }
      }
    }
  }
}

#search_autocomplete {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);

  @include bp(max-width, $bp-tiny) {
    right: 0;
    top: 56px !important;
  }

  .search-more {
    font-style: italic;
  }
}

#mini-account {
  .mini-account-content {
    padding: 0;
  }

  .caret {
    display: none;
  }
}

[id^="mini-account"] {
  > a {
    display: inline-block;
    vertical-align: middle;
    padding-right: 12px;
  }
}

.hp-blocks-holder {
  //.tagline,
  .item {
    float: left;
  }
}

//.tagline {
//  width: calc(100% - 170px);
//  font-style: italic;
//  color: #a3b5c0;
//
//  @include bp(max-width, 960px){
//    display: none;
//  }
//
//  div {
//    display: table-cell;
//    height: 80px;
//    vertical-align: middle;
//    padding-left: 50px;
//  }
//}

#header-search {
  padding-top: 17px;
  margin-right: 35px;
}

.header-primary {
  .left-column {
    width: 37%;
  }

  .right-column {
    width: 68%;

    .item-right {
      width: 100%;
    }
  }
}

#mini-account-wrapper-regular-slip .ic-user:before {
  font-size: 30px;
  float: left;
  position: relative;
  top: 3px;
  margin-right: 8px;  
}

.header-top-container {
  .container_header_top_block {
    float: right;

    .container_header_top_right_1:not(.callback) {
      float: left;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

.ic-menu {
  &:before {
    font-family: icomoon4;
    content: '\e905';
  }
}

.ic-search {
  &:before {
    font-family: icomoon4;
    content: '\e908';
  }
}
